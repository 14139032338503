export const typeSafeObjectEntries = <
  Key extends string | number | symbol,
  Value,
>(
  object: Record<Key, Value>
): [Key, Value][] => {
  const entries: [Key, Value][] = [];
  for (const key in object) {
    entries.push([key, object[key]]);
  }
  return entries;
};
