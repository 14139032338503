import { SummaryTag, SummaryTagEnum } from "src/store/api/generatedApi";

export type DiscriminatedSummaryTag<Tag extends SummaryTagEnum> = Omit<
  SummaryTag,
  "tag"
> & { tag: Tag };

export const groupSummaryTags = (
  tags: SummaryTag[]
): [
  DiscriminatedSummaryTag<"challenging_for_scrapyard">[],
  DiscriminatedSummaryTag<"not_enough_available_scrap">[],
  DiscriminatedSummaryTag<"uses_non_default_baskets">[],
] => {
  return [
    tags.filter(
      (
        tag: SummaryTag
      ): tag is DiscriminatedSummaryTag<"challenging_for_scrapyard"> =>
        tag.tag === "challenging_for_scrapyard"
    ),
    tags.filter(
      (
        tag: SummaryTag
      ): tag is DiscriminatedSummaryTag<"not_enough_available_scrap"> =>
        tag.tag === "not_enough_available_scrap"
    ),
    tags.filter(
      (
        tag: SummaryTag
      ): tag is DiscriminatedSummaryTag<"uses_non_default_baskets"> =>
        tag.tag === "uses_non_default_baskets"
    ),
  ];
};
