import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  List,
  ListItemButton,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { MouseEventHandler, useState } from "react";
import { useTenantTranslation } from "hooks/formatters";
import {
  ListUploadedFileTypesApiResponse,
  SaveUploadedFileApiArg,
  UploadedFileMeta,
  UploadedFileType,
  useListUploadedFilesQuery,
  useSaveUploadedFileMutation,
} from "store/api/generatedApi";
import { ReactNode } from "react";
import { useTenant } from "hooks/settings";
import { getBase64 } from "helpers";
import { CloudUpload, Delete, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { LoadingWheel } from "../../loading/loadingWheel";
import { Select } from "../../inputs/select";

export const planFileTypeTranslations = {
  melt_plan: "meltPlan",
  order_book: "orderBook",
  scrap_plan: "scrapPlan",
  contracted_buy: "contractedBuy",
  scrap_composite: "scrapComposite",
  forecasted_buy: "forecastedBuy",
  inventory_measurement: "inventoryMeasurement",
  inventory_report: "inventoryReport",
  twenty_six_week_schedule: "twentySixWeekSchedule",
  steel_grade_plan: "steelGradePlan",
  steel_grade_billet_size: "steelGradeBilletSize",
  steel_grade_chemistry: "steelGradeChemistry",
  scrap_types: "scrapTypes",
  rail_car_movements: "railCarMovements",
  scrap_usage: "scrapUsage",
  chemical_samples: "chemicalSamples",
  ship_deliveries: "shipDeliveries",
  truck_deliveries: "truckDeliveries",
  purchased_scrap: "purchasedScrap",
  deliveries: "deliveries",
  billet_stock: "billetStock",
  recipes: "recipes",
  fdm_recipes: "fdm_recipes",
  heat_mass: "heatMass",
  unknown: "unknown",
  chemistry_samples: "chemistrySamples",
  heat_process_data: "heatProcessData",
  heat_scrap_data: "heatScrapData",
  material_metadata: "materialMetadata",
  other_material_additions: "otherMaterialAdditions",
  product_chemical_analysis: "productChemicalAnalysis",
  quality_codes: "qualityCodes",
  slag_analysis: "slagAnalysis",
  sap_billet_orders: "sapBilletOrders",
  sap_rolled_orders: "sapRolledOrders",
  drop_weights: "dropWeights",
  scrap_car_weight: "scrapCarWeight",
} as const;

export const SelectPlanFile = ({
  planFile,
  setPlanFile,
  planFileType,
  setUploadedFileType,
  planFileTypeOptions,
  disabled,
}: {
  planFile: UploadedFileMeta | null;
  setPlanFile: (planFile: UploadedFileMeta | null) => Promise<void>;
  planFileType: UploadedFileType | undefined;
  setUploadedFileType: (planFileType: UploadedFileType) => void;
  planFileTypeOptions: ListUploadedFileTypesApiResponse;
  disabled?: boolean;
}) => {
  const tenant = useTenant();
  const { t } = useTenantTranslation();

  const [savePlanFile, { isLoading: isPlanFileLoading }] =
    useSaveUploadedFileMutation();
  const [openSelectExistingFileModal, setOpenSelectExistingFileModal] =
    useState(false);
  const [showError, setShowError] = useState(false);

  const handleUpload = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file === undefined) {
      void setPlanFile(null);
    } else {
      const fileContent = await getBase64(file);
      if (typeof fileContent !== "string") {
        setShowError(true);
      } else {
        const saveArg: SaveUploadedFileApiArg = {
          tenantName: tenant,
          uploadedFileCreate: {
            type: planFileType!,
            file: {
              name: file.name,
              updated_at: new Date(file.lastModified).toISOString(),
              content: fileContent,
            },
          },
        };

        savePlanFile(saveArg)
          .unwrap()
          .then(setPlanFile)
          .catch(() => setShowError(true));
      }
    }
  };

  const handleSearch = () => {
    setOpenSelectExistingFileModal(true);
  };

  const handleClear: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();

    void setPlanFile(null);
  };

  return (
    <>
      <Stack spacing={3} sx={{ mt: 1 }}>
        <Select
          options={planFileTypeOptions}
          value={planFileType ?? null}
          setValue={setUploadedFileType}
          label={t("fileType")}
          format={(planType) => ({
            key: planType,
            label: t(planFileTypeTranslations[planType]),
          })}
          disabled={disabled}
        />
        {planFileType !== undefined && (
          <>
            {planFile === null ? (
              <FileSelector
                handleUpload={handleUpload}
                handleSearch={handleSearch}
                isLoading={isPlanFileLoading}
              />
            ) : (
              <FilePreview planFile={planFile} handleClear={handleClear} />
            )}
            <SelectExistingFileModal
              open={openSelectExistingFileModal}
              setOpen={setOpenSelectExistingFileModal}
              setPlanFile={setPlanFile}
              planFileType={planFileType}
            />
          </>
        )}
      </Stack>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">{t("failedToUploadedFile")}</Alert>
      </Snackbar>
    </>
  );
};

const FileSelector = ({
  handleUpload,
  handleSearch,
  isLoading,
}: {
  handleUpload: (acceptedFiles: File[]) => Promise<void>;
  handleSearch: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTenantTranslation();
  return (
    <Stack
      spacing={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dropzone onDrop={(file) => void handleUpload(file)}>
        {({ getRootProps, getInputProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...getRootProps()}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            <LoadingButton
              variant="outlined"
              startIcon={<CloudUpload />}
              loading={isLoading}
            >
              {t("uploadNewFile")}
            </LoadingButton>
          </div>
        )}
      </Dropzone>
      <Typography variant="body1">or</Typography>
      <LoadingButton
        variant="outlined"
        startIcon={<Search />}
        onClick={handleSearch}
        loading={isLoading}
      >
        {t("chooseExistingFile")}
      </LoadingButton>
    </Stack>
  );
};

const FilePreview = ({
  planFile,
  handleClear,
}: {
  planFile: UploadedFileMeta;
  handleClear: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "grey.100",
        borderRadius: 1,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{planFile.file.name}</Typography>
      <IconButton onClick={handleClear}>
        <Delete />
      </IconButton>
    </Box>
  );
};

const SelectExistingFileModal = ({
  open,
  setOpen,
  setPlanFile,
  planFileType,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setPlanFile: (planFile: UploadedFileMeta) => Promise<void>;
  planFileType: UploadedFileType;
}) => {
  const { t } = useTenantTranslation();
  const handleClose = () => setOpen(false);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("chooseExistingFile")}</DialogTitle>
      <DialogContent>
        <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
          <SelectExistingPlanFileContent
            setPlanFile={setPlanFile}
            closeModal={handleClose}
            planFileType={planFileType}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const SelectExistingPlanFileContent = ({
  setPlanFile,
  closeModal,
  planFileType,
}: {
  setPlanFile: (planFile: UploadedFileMeta) => Promise<void>;
  closeModal: () => void;
  planFileType: UploadedFileType;
}) => {
  const { t } = useTenantTranslation();

  const { data: planFiles, isError } = useListUploadedFilesQuery({
    tenantName: useTenant(),
  });

  if (isError) {
    return <Alert severity="error">{t("errorLoadingFiles")}</Alert>;
  } else if (planFiles === undefined) {
    return <LoadingWheel />;
  } else if (planFiles.length === 0) {
    return <Alert severity="info">{t("noFilesUploaded")}</Alert>;
  } else {
    return (
      <ClickableList
        items={planFiles
          .filter((planFile) => planFile.type === planFileType)
          .map((planFile) => ({
            child: <Typography>{planFile.file.name}</Typography>,
            identifier: planFile.id,
            onClick: () => {
              closeModal();
              void setPlanFile(planFile);
            },
          }))}
      />
    );
  }
};

type ClickableListItemParameters = {
  child: ReactNode;
  identifier: string | number;
  onClick: () => void;
};

const ClickableList = ({ items }: { items: ClickableListItemParameters[] }) => (
  <List sx={{ height: 1, overflow: "auto" }}>
    {items.map((item, index) => (
      <ClickableListItem
        key={item.identifier}
        {...item}
        divider={index < items.length - 1}
      />
    ))}
  </List>
);

const ClickableListItem = ({
  divider,
  onClick,
  child,
}: Omit<ClickableListItemParameters, "identifier"> & { divider: boolean }) => (
  <ListItemButton divider={divider} onClick={onClick}>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: 1, height: 30 }}
    >
      {child}
    </Stack>
  </ListItemButton>
);
