import { Grid, LinearProgress, Stack } from "@mui/material";
import { InputsOutdatedAlert } from "../../common/alerts/inputsOutdated";
import { useTenant } from "hooks/settings";
import { useSearch, useServerIds } from "contexts/search/provider";
import { ConstraintsAlert } from "../../common/alerts/Constraints";
import { RunConditions, useGetRunConditions } from "./RunConditions";
import { InputsSuboptimalAlert } from "components/common/alerts/inputsSuboptimal";

type Props = {
  children: React.ReactNode;
  isNewSearch: boolean;
  inputsStale: boolean;
  constraintsStale: boolean;
  longestSuboptimalTimeLimit: number | undefined;
};

export const SearchResultsLayout = ({
  children,
  isNewSearch,
  inputsStale,
  constraintsStale,
  longestSuboptimalTimeLimit,
}: Props) => {
  const { setSearchId } = useSearch();
  const { searchId: serverSearchId } = useServerIds();
  const tenantName = useTenant();

  const runConditions = useGetRunConditions();
  if (!runConditions) return <LinearProgress />;

  return (
    <Grid container columns={8} sx={{ py: 4 }}>
      <Grid item xs={1} />
      <Grid item xs={6}>
        {inputsStale ||
        constraintsStale ||
        isNewSearch ||
        longestSuboptimalTimeLimit !== undefined ||
        !runConditions.areRunConditionsMet ? (
          <Stack sx={{ mb: 5 }} spacing={2}>
            {constraintsStale && serverSearchId ? (
              <ConstraintsAlert
                searchId={serverSearchId}
                tenantName={tenantName}
                onRefresh={(searchId) => setSearchId(searchId)}
              />
            ) : null}
            {inputsStale ? (
              <InputsOutdatedAlert />
            ) : longestSuboptimalTimeLimit !== undefined ? (
              <InputsSuboptimalAlert
                previousTimeLimit={longestSuboptimalTimeLimit}
              />
            ) : null}
            {isNewSearch || !runConditions.areRunConditionsMet ? (
              <RunConditions />
            ) : null}
          </Stack>
        ) : null}
        {children}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};
