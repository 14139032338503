import { SummaryTag } from "src/store/api/generatedApi";
import { DiscriminatedSummaryTag } from "./groupSummaryTags";

export type DescribedDiscriminatedSummaryTag =
  | DiscriminatedSummaryTag<"challenging_for_scrapyard">
  | DiscriminatedSummaryTag<"not_enough_available_scrap">;

export const getDescribedSummaryTags = (
  summaryTags: SummaryTag[]
): DescribedDiscriminatedSummaryTag[] =>
  summaryTags.filter(
    (tag): tag is DescribedDiscriminatedSummaryTag =>
      tag.tag !== "uses_non_default_baskets"
  );
