import { usePeriods } from "contexts/search/provider";
import { useTenantTranslation } from "hooks/formatters";
import { Period } from "hooks/periodIndex";

export const usePeriodName = () => {
  const { t } = useTenantTranslation();
  const [searchPeriods] = usePeriods();

  return (period: Period) => {
    switch (period) {
      case 1: {
        if (searchPeriods.status === "success" && searchPeriods.data[0]?.name) {
          return searchPeriods.data[0].name;
        }
        return t("schedule");
      }
      default: {
        const periodIndex = period - 1;
        if ("data" in searchPeriods && searchPeriods.data[periodIndex]?.name) {
          return searchPeriods.data[periodIndex]!.name;
        }
        return `${t("forecastPastParticiple")} ${period - 1}`;
      }
    }
  };
};
