import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

interface StatusIndicatorProps {
  status: "under" | "over" | "complete";
}

type StatusColour = "success.main" | "error.main" | "warning.main";

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  const getColour = (): StatusColour => {
    switch (status) {
      case "complete":
        return "success.main";
      case "over":
        return "error.main";
      case "under":
        return "warning.main";
    }
  };

  return <CircleIcon sx={{ color: getColour() }} />;
};
