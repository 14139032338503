import {
  useDeleteUserInviteMutation,
  useGetPendingInvitesQuery,
} from "store/api/generatedApi";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PendingInviteTableRow } from "./pendingInviteTableRow";
import { Fetching } from "src/components/common/loading/fetching";
import { skipToken } from "@reduxjs/toolkit/query/react";

export const PendingInvitesList = ({
  tenant,
  onError,
}: {
  tenant: string;
  onError: (message: string) => void;
}) => {
  const { data: invites, isFetching } = useGetPendingInvitesQuery(
    tenant
      ? {
          tenantName: tenant,
        }
      : skipToken
  );
  const [deleteInvite] = useDeleteUserInviteMutation();

  const handleDeleteInvite = async (email: string) => {
    await deleteInvite({
      tenantName: tenant,
      email: email,
    })
      .unwrap()
      .catch(() => onError(`Error deleting invite for user ${email}.`));
  };

  return (
    <Fetching fetching={isFetching}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Invited At</TableCell>
            <TableCell align="center">Delete Invite</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invites?.map((invite) => {
            return (
              <PendingInviteTableRow
                key={`${tenant}-${invite.email}`}
                invitedUser={invite}
                onDelete={() => void handleDeleteInvite(invite.email)}
              />
            );
          })}
        </TableBody>
      </Table>
    </Fetching>
  );
};
