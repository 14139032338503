import {
  AppBar,
  Box,
  Divider,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTenantTranslation } from "hooks/formatters";
import { useIsAdmin, useTenant } from "hooks/settings";
import { ScrapChefIcon } from "./ScrapChefIcon";
import { SettingsMenu } from "./SettingsMenu";
import { SearchDetails } from "./SearchDetails";
import { useSearchId } from "hooks/search";
import { useCanDeploy } from "hooks/useCanDeploy";
import { useIsBehindFeatureFlag } from "hooks/useFeatureFlag";

export const Navbar = () => {
  const { t } = useTenantTranslation();
  const tenant = useTenant();

  const searchId = useSearchId();
  const isAdmin = useIsAdmin();
  const canDeploy = useCanDeploy();
  const isSearchContextGroupsBehindFeatureFlag = useIsBehindFeatureFlag(
    "search_context_groups"
  );
  const canUseSearchContextGroups =
    isAdmin && !isSearchContextGroupsBehindFeatureFlag;

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{
          backgroundColor: "#000000",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={3}
          sx={{ width: "100%" }}
        >
          <Link
            key="root"
            to={`/${tenant}/v3`}
            underline="none"
            color="inherit"
            component={RouterLink}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ScrapChefIcon />
            </Box>
          </Link>
          {searchId !== null ? (
            <>
              <Divider flexItem orientation="vertical" variant="fullWidth" />
              <SearchDetails id={searchId} />
            </>
          ) : null}
          {canUseSearchContextGroups ? (
            <Link
              sx={{ marginLeft: "auto" }}
              key="search_context_group"
              to={`/${tenant}/v3/search_context_group`}
              underline="always"
              color="#ffffff"
              component={RouterLink}
            >
              <Typography variant="button" sx={{ whiteSpace: "nowrap" }}>
                {t("searchContextGroups")}
              </Typography>
            </Link>
          ) : null}
          <Link
            sx={!canUseSearchContextGroups ? { marginLeft: "auto" } : {}}
            key="searches"
            to={`/${tenant}/v3/`}
            underline="always"
            color="#ffffff"
            component={RouterLink}
          >
            <Typography variant="button" sx={{ whiteSpace: "nowrap" }}>
              {t("searches")}
            </Typography>
          </Link>
          {canDeploy ? (
            <Link
              key="deployed-plans"
              to={`/${tenant}/v3/deployed-plans`}
              underline="always"
              color="#ffffff"
              component={RouterLink}
            >
              <Typography variant="button" sx={{ whiteSpace: "nowrap" }}>
                {t("deployedPlans")}
              </Typography>
            </Link>
          ) : null}
          <SettingsMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
