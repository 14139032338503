import React from "react";
import equal from "fast-deep-equal";
import { Stack, Typography, Chip } from "@mui/material";
import {
  BasketMaterialLimitId,
  ChefGroupBasketMaterialLimitId,
  FlattenedChefGroupBasketMaterialLimit,
  FlattenedChefGroupBasketMaterialLimitId,
  MixMaterialLimitId,
} from "contexts/search/context";
import { MaterialLimitChip } from "components/common/chips/materialLimit";
import { useTenantTranslation } from "hooks/formatters";
import {
  useChefGroupBasketMaterialLimits,
  useChefGroupBasketMaterialLimitsSlice,
} from "src/store/hooks/chefGroupBasketMaterialLimits";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { BasketMaterialLimitEditorDialog } from "./basketMaterialLimitEditorDialog";
import { groupBasketMaterialLimitsByBasket } from "src/utils/groupBasketMaterialLimitsByBasket";
import {
  Id,
  MaterialLimit,
} from "components/common/forms/MaterialLimitsEditor";
import { Limits } from "src/utils/addNewLimit";
import {
  useChefGroupMixMaterialLimits,
  useMixMaterialLimitsSlice,
} from "src/store/hooks/mixMaterialLimits";
import { useIsAdmin } from "hooks/settings";
import { useBaskets } from "contexts/search/provider";
import { BasketRead } from "src/store/api/generatedApi";
import { typeSafeObjectMap } from "src/utils/typeSafeObjectMap";

/**
 * Shows each of a series of chemistry constraints in small panels.
 */

const createInitialMaterialLimit = (baskets: BasketRead[]): MaterialLimit => ({
  name: "",
  baskets: typeSafeObjectFromEntries(
    baskets.map((basket) => [
      basket.id,
      {
        basketId: basket.id,
        number: basket.basket_number,
        volume: basket.basket_volume,
        limits: [null, null, null, null],
      },
    ])
  ),
  hardness: 1,
  coefficients: {},
  mixLimits: [null, null, null, null],
});

export const BasketMaterialLimits = ({
  chefGroupId,
}: {
  chefGroupId: number;
}) => {
  const basketMaterialLimits = useChefGroupBasketMaterialLimits(chefGroupId);
  const mixMaterialLimits = useChefGroupMixMaterialLimits(chefGroupId);
  const isAdmin = useIsAdmin();

  const { edit: editBasketMaterialLimit } =
    useChefGroupBasketMaterialLimitsSlice();
  const { edit: editMixMaterialLimit, add: addMixMaterialLimit } =
    useMixMaterialLimitsSlice();

  const { t } = useTenantTranslation();

  const [inEdit, setInEdit] = React.useState<
    | null
    | [
        MaterialLimit,
        BasketMaterialLimitId,
        string,
        MixMaterialLimitId | null,
        Record<
          Id,
          [
            FlattenedChefGroupBasketMaterialLimitId,
            ChefGroupBasketMaterialLimitId,
          ]
        >,
      ]
  >(null);

  const handleSelect = (
    basketMaterialLimit: FlattenedChefGroupBasketMaterialLimit
  ) => {
    const baskets = typeSafeObjectFromEntries(
      basketMaterialLimits
        .filter(
          ({ basket_material_limit_id }) =>
            basket_material_limit_id ===
            basketMaterialLimit.basket_material_limit_id
        )
        .map((basketMaterialLimit) => {
          const {
            basket_id,
            basket_number,
            basket_volume,
            chef_group_basket_material_limit_id,
            min_mass,
            soft_min_mass,
            soft_max_mass,
            max_mass,
            id,
          } = basketMaterialLimit;
          return [
            basket_id as Id,
            {
              id,
              basketId: basket_id,
              number: basket_number,
              volume: basket_volume,
              chefGroupBasketMaterialLimitId:
                chef_group_basket_material_limit_id,
              limits: [
                min_mass,
                soft_min_mass,
                soft_max_mass,
                max_mass,
              ] as Limits,
            },
          ];
        })
    );

    const basketIdToIds = typeSafeObjectMap(
      baskets,
      (basket_id, { id, chefGroupBasketMaterialLimitId }) => [
        basket_id,
        [id, chefGroupBasketMaterialLimitId] as [
          FlattenedChefGroupBasketMaterialLimitId,
          ChefGroupBasketMaterialLimitId,
        ],
      ]
    );

    const mix = mixMaterialLimits.find((mixMaterialLimit) =>
      equal(
        typeSafeObjectFromEntries(
          mixMaterialLimit.coefficients.map(
            ({ material_constraint_class_id, coefficient }) => [
              material_constraint_class_id,
              coefficient,
            ]
          )
        ),
        typeSafeObjectFromEntries(
          basketMaterialLimit.coefficients.map(
            ({ material_constraint_class_id, coefficient }) => [
              material_constraint_class_id,
              coefficient,
            ]
          )
        )
      )
    );

    const {
      name,
      hardness,
      coefficients,
      coefficients_signature,
      basket_material_limit_id,
    } = basketMaterialLimit;

    setInEdit([
      {
        name,
        baskets,
        mixLimits: mix
          ? [mix.min_mass, mix.soft_min_mass, mix.soft_max_mass, mix.max_mass]
          : [null, null, null, null],
        hardness,
        coefficients: typeSafeObjectFromEntries(
          coefficients.map(({ material_constraint_class_id, coefficient }) => [
            material_constraint_class_id,
            coefficient,
          ])
        ),
      },
      basket_material_limit_id,
      coefficients_signature,
      mix ? mix.id : null,
      basketIdToIds,
    ]);
  };

  const handleSubmit = React.useCallback(
    (
      {
        name,
        hardness,
        coefficients: coefficients_,
        baskets,
        mixLimits,
      }: MaterialLimit,
      basketMaterialLimitId: BasketMaterialLimitId,
      mixMaterialLimitId: null | MixMaterialLimitId,
      coefficientsSignature: string,
      chefGroupId: number,
      basketIdToFlattenedChefGroupBasketMaterialLimitId: Record<
        Id,
        [
          FlattenedChefGroupBasketMaterialLimitId,
          ChefGroupBasketMaterialLimitId,
        ]
      >
    ) => {
      const coefficients = typeSafeObjectEntries(coefficients_).map(
        ([material_constraint_class_id, coefficient]) => ({
          material_constraint_class_id,
          coefficient,
        })
      );
      if (mixMaterialLimitId !== null) {
        const [min_mass, soft_min_mass, soft_max_mass, max_mass] = mixLimits;
        editMixMaterialLimit({
          id: mixMaterialLimitId,
          chef_group_id: chefGroupId,
          min_mass,
          max_mass,
          soft_min_mass,
          soft_max_mass,
          hardness,
          name,
          coefficients,
          coefficients_signature: coefficientsSignature,
        });
      } else if (mixLimits.some((limit) => limit !== null)) {
        const [min_mass, soft_min_mass, soft_max_mass, max_mass] = mixLimits;
        addMixMaterialLimit({
          chef_group_id: chefGroupId,
          min_mass,
          max_mass,
          soft_min_mass,
          soft_max_mass,
          hardness,
          name,
          coefficients,
        });
      }
      typeSafeObjectEntries(baskets).forEach(
        ([
          id,
          {
            basketId: basket_id,
            volume: basket_volume,
            number: basket_number,
            limits: [min_mass, soft_min_mass, soft_max_mass, max_mass],
          },
        ]) => {
          editBasketMaterialLimit({
            id: basketIdToFlattenedChefGroupBasketMaterialLimitId[id]![0],
            chef_group_id: chefGroupId,
            min_mass,
            max_mass,
            soft_min_mass,
            soft_max_mass,
            hardness: hardness,
            coefficients,
            name: name,
            coefficients_signature: coefficientsSignature,
            basket_id,
            basket_number,
            basket_volume,
            chef_group_basket_material_limit_id:
              basketIdToFlattenedChefGroupBasketMaterialLimitId[id]![1],
            basket_material_limit_id: basketMaterialLimitId,
          });
        }
      );
    },
    [editBasketMaterialLimit, editMixMaterialLimit, addMixMaterialLimit]
  );

  const baskets = useBaskets();

  return (
    <Stack gap={2}>
      {Object.entries(
        groupBasketMaterialLimitsByBasket(basketMaterialLimits)
      ).map(([basketNumber, { bounds, chef_group_id }]) => (
        <Stack gap={1} key={basketNumber}>
          <Typography>
            {t("basketNumber")} {basketNumber}
          </Typography>
          <Stack flexDirection="row" flexWrap="wrap">
            {bounds.map((bound) => (
              <MaterialLimitChip
                key={bound.basket_material_limit_id}
                minMass={bound.min_mass}
                maxMass={bound.max_mass}
                name={bound.name}
                onClick={() => handleSelect(bound)}
              />
            ))}
            {isAdmin && baskets.status === "success" ? (
              <AddBasketMaterialLimit
                chefGroupId={chef_group_id}
                baskets={baskets.data}
              />
            ) : null}
          </Stack>
        </Stack>
      ))}
      {inEdit ? (
        <BasketMaterialLimitEditorDialog
          doClose={() => setInEdit(null)}
          materialLimit={inEdit[0]}
          title={t("editMaterialLimit")}
          onSubmit={(materialLimit) => {
            const [
              ,
              basketMaterialLimitId,
              coefficientsSignature,
              mixMaterialLimitId,
              basketIdToIds,
            ] = inEdit;

            handleSubmit(
              materialLimit,
              basketMaterialLimitId,
              mixMaterialLimitId,
              coefficientsSignature,
              chefGroupId,
              basketIdToIds
            );
          }}
        />
      ) : null}
    </Stack>
  );
};

type AddBasketMaterialLimitProps = {
  chefGroupId: number;
  baskets: BasketRead[];
};

const AddBasketMaterialLimit = ({
  chefGroupId,
  baskets,
}: AddBasketMaterialLimitProps) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTenantTranslation();

  const { add: addBasketMaterialLimit } =
    useChefGroupBasketMaterialLimitsSlice();
  const { add: addMixMaterialLimit } = useMixMaterialLimitsSlice();

  const handleSubmit = React.useCallback(
    (materialLimit: MaterialLimit) => {
      const { mixLimits, coefficients, name, hardness, baskets } =
        materialLimit;

      Object.values(baskets).forEach((basket) => {
        if (basket.limits.some((limit) => limit !== null)) {
          const {
            limits: [min_mass, soft_min_mass, soft_max_mass, max_mass],
            basketId: basket_id,
            number: basket_number,
            volume: basket_volume,
          } = basket;
          addBasketMaterialLimit({
            name,
            min_mass,
            max_mass,
            soft_min_mass,
            soft_max_mass,
            coefficients: typeSafeObjectEntries(coefficients).map(
              ([material_constraint_class_id, coefficient]) => ({
                material_constraint_class_id,
                coefficient,
              })
            ),
            hardness,
            chef_group_id: chefGroupId,
            basket_id,
            basket_number,
            basket_volume,
          });
        }
      });

      if (mixLimits.some((limit) => limit !== null)) {
        const [min_mass, soft_min_mass, soft_max_mass, max_mass] = mixLimits;
        addMixMaterialLimit({
          name,
          hardness,
          coefficients: typeSafeObjectEntries(coefficients).map(
            ([material_constraint_class_id, coefficient]) => ({
              material_constraint_class_id,
              coefficient,
            })
          ),
          min_mass,
          soft_min_mass,
          soft_max_mass,
          max_mass,
          chef_group_id: chefGroupId,
        });
      }
    },
    [addBasketMaterialLimit, addMixMaterialLimit, chefGroupId]
  );

  return (
    <>
      <Chip
        sx={{ mr: 1, mb: 1 }}
        label="+"
        size="small"
        color="primary"
        onClick={() => setOpen(true)}
      />
      {open ? (
        <BasketMaterialLimitEditorDialog
          doClose={() => setOpen(false)}
          onSubmit={handleSubmit}
          title={t("createMaterialLimit")}
          materialLimit={createInitialMaterialLimit(baskets)}
        />
      ) : null}
    </>
  );
};
