import equal from "fast-deep-equal";
import { sorted } from "helpers";
import { useTenant } from "hooks/settings";
import { SyncedState, useSyncedState } from "hooks/state/syncing";
import { useMemo } from "react";
import { backendApi } from "src/store/api/enhancedApi";
import {
  SyncedSearchParameters,
  SyncedSearchPeriod,
  useCreateSearchFromParametersMutation,
} from "src/store/api/generatedApi";

export type SyncedParametersState = SyncedState<SyncedSearchParameters>;

export const useSyncedParametersState = (
  clientSearchId: number | null,
  revision?: number,
  preSync?: () => void
): SyncedParametersState => {
  const tenant = useTenant();

  const [loadSearch] = backendApi.endpoints.getSearch.useLazyQuery();
  const [syncSearch] = useCreateSearchFromParametersMutation();

  const source = useMemo(() => {
    if (clientSearchId == null) {
      return null;
    } else {
      return async () =>
        loadSearch({ tenantName: tenant, searchId: clientSearchId }).unwrap();
    }
  }, [clientSearchId, revision]);

  return useSyncedState<SyncedSearchParameters>(
    source,
    async (newSearch, parentSearch) => {
      if (preSync != null) {
        preSync();
      }
      return syncSearch({
        tenantName: tenant,
        syncedSearchParametersInput: {
          ...newSearch,
          parent_id: parentSearch.id,
        },
      }).unwrap();
    },
    (left, right) => equal(sortedParameters(left), sortedParameters(right))
  );
};

const sortedParameters = (parameters: SyncedSearchParameters) => ({
  misc_params: parameters.misc_params,
  inventory: sorted(parameters.inventory, (item) => item.material_id),
  target_baskets: sorted(
    parameters.target_baskets,
    (item) => item.chef_group_id
  ),
  periods: parameters.periods.map(sortedPeriod),
});

const sortedPeriod = (period: SyncedSearchPeriod) => ({
  name: period.name,
  start_timestamp:
    period.start_timestamp == null
      ? null
      : new Date(period.start_timestamp).toISOString(),
  end_timestamp:
    period.end_timestamp == null
      ? null
      : new Date(period.end_timestamp).toISOString(),
  product_groups: sorted(
    period.production_plan.product_group_items ?? [],
    (item) => item.product_group_id
  ),
  steel_grades: sorted(
    period.production_plan.steel_grade_items ?? [],
    (item) => item.steel_grade_id
  ),
  obtainable_bundles: sorted(period.obtainable_bundles, (item) => item.uuid),
  target_inventory: sorted(period.target_inventory, (item) => item.material_id),
  material_exclusivity: sorted(period.material_exclusivity, (item) =>
    sorted(item.material_ids, (id) => id)
  ),
  suppress_mix_material_exclusivity_constraints:
    period.suppress_mix_material_exclusivity_constraints,
  suppress_min_feasible_mass_constraints:
    period.suppress_min_feasible_mass_constraints,
  optimisation_objective_weighting: period.optimisation_objective_weighting,
  electrical_energy_price: period.electrical_energy_price,
  is_deployable: period.is_deployable,
});
