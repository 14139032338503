import { Box, Stack, Typography } from "@mui/material";
import { useTenantTranslation } from "src/hooks/formatters";
import { AccountPageLayout } from "components/common/AccountPageLayout";
import { ProfileSettingsFormItem } from "./ProfileSettingsFormItem";
import { useUserEmail } from "src/hooks/settings";
import { SelectLocale } from "components/settings/locale";
import { useTenantData } from "contexts/tenant";

export const ProfileSettingsPage = () => {
  const { t } = useTenantTranslation();
  const email = useUserEmail();
  const tenantData = useTenantData();

  return (
    <AccountPageLayout title={t("userSettings")}>
      <Box
        sx={{
          mt: 2,
          px: 2,
          py: 1,
          borderRadius: 0.5,
          backgroundColor: "grey.100",
          width: "fit-content",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {t("changeSettingsInstructions")}
        </Typography>
      </Box>
      <Stack spacing={3} sx={{ mt: 5 }}>
        <ProfileSettingsFormItem label={t("userEmail")} value={email ?? ""} />
        <ProfileSettingsFormItem
          label={t("userCompany")}
          value={tenantData.long_name}
        />
        <ProfileSettingsFormItem
          label={t("language")}
          value={
            <Box sx={{ maxWidth: 400 }}>
              <SelectLocale hideLabel />
            </Box>
          }
        />
      </Stack>
    </AccountPageLayout>
  );
};
