import { useNotifyStatus } from "contexts/status";
import { useTenant, useUserEmail } from "hooks/settings";
import { useState } from "react";
import {
  useListAllMaterialsQuery,
  useWriteVideoLabelsMutation,
} from "src/store/api/generatedApi";
import { v4 } from "uuid";
import { Label } from "./page";
import { sorted } from "helpers";

export const useVideoLabels = () => {
  const tenant = useTenant();
  const [labels, setLabels] = useState<Label[]>([]);
  const [writeVideoLabels] = useWriteVideoLabelsMutation();
  const user = (useUserEmail() ?? "unknownuser").split("@")[0]!;

  const notifyStatus = useNotifyStatus();

  const saveLabels = (key: string, description?: string, duration?: number) => {
    if (labels.length === 0) {
      const saveAnyway = confirm(
        "Save video with no labels (OK) " +
          "or continue without saving labels for this video (Cancel)?"
      );
      if (!saveAnyway) {
        return;
      }
    }

    return writeVideoLabels({
      tenantName: tenant,
      videoLabels: {
        key,
        user,
        description: description ?? v4().toString().split("-").join(""),
        labels,
        duration,
      },
    })
      .unwrap()
      .then((path) => {
        notifyStatus({
          type: "success",
          text: `Saved ${labels.length} labels (${path})`,
        });
        setLabels([]);
        return path;
      })
      .catch(() =>
        notifyStatus({ type: "error", text: "Failed to save labels" })
      );
  };

  const addLabel = (label: Label) => {
    if (label.end > label.start) {
      setLabels((current) =>
        sorted(
          [...current.filter((item) => item.end < label.start), label],
          (item) => item.start
        )
      );
    }
  };

  const deleteLabel = (label: Label) =>
    setLabels((current) =>
      current.filter((item) => item.start !== label.start)
    );

  const deleteLastLabel = () => {
    if (labels.length === 0) {
      return null;
    } else {
      const label = labels[labels.length - 1]!;
      deleteLabel(label);
      return label;
    }
  };

  return [
    labels,
    setLabels,
    { saveLabels, addLabel, deleteLabel, deleteLastLabel },
  ] as const;
};

export const useMaterialNames = () => {
  const tenant = useTenant();
  const { data: materials, isLoading } = useListAllMaterialsQuery({
    tenantName: tenant,
  });

  const loading = isLoading || materials === undefined;

  return [materials?.map((material) => material.name), loading] as const;
};
