import { createSelector } from "@reduxjs/toolkit";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import React from "react";
import { MixMaterialLimitId } from "contexts/search/context";
import {
  CreateReduxMixMaterialLimit,
  mixMaterialLimitsSlice,
  ReduxMixMaterialLimit,
} from "../slices/mixMaterialLimits";

export const selectMixMaterialLimits = createSelector(
  [
    (state: RootState) => state.mixMaterialLimits.mixMaterialLimits,
    (_: RootState, chefGroupId?: number) => chefGroupId,
    (_: RootState, __?: number, coefficientsSignature?: string) =>
      coefficientsSignature,
  ],
  (mixMaterialLimits, chefGroupId, coefficientsSignature) =>
    Object.values(mixMaterialLimits).filter(
      ({ chef_group_id, coefficients_signature }) =>
        (chefGroupId !== undefined ? chef_group_id === chefGroupId : true) &&
        (coefficientsSignature !== undefined
          ? coefficients_signature === coefficientsSignature
          : true)
    )
);

export const useChefGroupMixMaterialLimits = (
  chefGroupId: number,
  coefficientsSignature?: string
) => {
  return useAppSelector((state) =>
    selectMixMaterialLimits(state, chefGroupId, coefficientsSignature)
  );
};

export const useMixMaterialLimits = () => {
  return useAppSelector((state) => state.mixMaterialLimits.mixMaterialLimits);
};

export const useMixMaterialLimitsArray = () => {
  return Object.values(useMixMaterialLimits());
};

export const useSlug = () =>
  useAppSelector((state) => state.mixMaterialLimits.slug);

export const useHaveMixMaterialLimitsMutated = () => {
  const mixMateriaLimits = useMixMaterialLimits();
  const slug = useSlug();
  return slug !== null && JSON.stringify(mixMateriaLimits) !== slug;
};

export const useMixMaterialLimitsSlice = () => {
  const dispatch = useAppDispatch();
  const actions = React.useMemo(() => {
    return {
      dump: (
        id: number,
        mixMaterialLimits: Record<MixMaterialLimitId, ReduxMixMaterialLimit>
      ) => {
        dispatch(mixMaterialLimitsSlice.actions.dump([id, mixMaterialLimits]));
      },
      add: (newMixMaterialLimit: CreateReduxMixMaterialLimit) => {
        dispatch(mixMaterialLimitsSlice.actions.add(newMixMaterialLimit));
      },
      edit: (mixMaterialLimit: ReduxMixMaterialLimit) => {
        dispatch(mixMaterialLimitsSlice.actions.edit(mixMaterialLimit));
      },
      remove: (id: MixMaterialLimitId) => {
        dispatch(mixMaterialLimitsSlice.actions.remove(id));
      },
    };
  }, [dispatch]);
  return actions;
};
