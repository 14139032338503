import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import {
  useGetSearchQuery,
  useRenameSessionMutation,
} from "src/store/api/generatedApi";
import { RenameSessionModal } from "../../searches/RenameSessionModal";
import { useTenant } from "hooks/settings";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";

type Props = {
  id: number;
};

export const SearchDetails = ({ id }: Props) => {
  const theme = useTheme();
  const formatDate = useFormatLocalDateString("datetime");
  const { t } = useTenantTranslation();
  const tenantName = useTenant();

  const [openModal, setOpenModal] = React.useState(false);
  const [renameSession] = useRenameSessionMutation();
  const search = useGetSearchQuery({ tenantName, searchId: id });

  const handleUpdateName = async ({
    tenantName,
    sessionId,
    name,
  }: {
    tenantName: string;
    sessionId: number;
    name: string;
  }) => {
    await renameSession({ tenantName, sessionId, renameSession: { name } });
    void search.refetch();
  };

  return search.isSuccess ? (
    <>
      <Box
        display="grid"
        gridTemplateColumns="max-content 1fr"
        alignItems="center"
        justifyItems="start"
        columnGap={1}
      >
        <Typography variant="body1">{search.data.session_name}</Typography>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setOpenModal(true)}
          sx={{ padding: 0 }}
        >
          <EditIcon fontSize="small" sx={{ alignSelf: "center" }} />
        </IconButton>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.grey[400],
            gridColumnStart: 1,
            gridColumnEnd: -1,
            textWrap: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {t("createdAtBy", {
            time: formatDate(search.data.session_created_at),
            name: search.data.session_created_by_email,
          })}
        </Typography>
      </Box>
      <RenameSessionModal
        originalName={search.data.session_name}
        prefillName={search.data.session_name}
        open={openModal}
        close={() => setOpenModal(false)}
        rename={(name) =>
          handleUpdateName({
            tenantName,
            sessionId: search.data.session_id,
            name,
          })
        }
      />
    </>
  ) : null;
};
