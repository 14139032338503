import React from "react";

import { ChefGroupSummary, SimilarChargedRecipesTab } from "./components";
import { useTenantTranslation } from "hooks/formatters";
import { ChefGroupProperties } from "./properties";
import { LayeringTab } from "./layering";
import { TabSpec, TitledTabs } from "src/components/common/titledTabs";
import { SteelGradesId } from "contexts/search/context";
import {
  OptimisationResultSetSummary,
  ChemicalElement,
  MixBasketSummary,
  MixMaterialBasketSummary,
  ChemicalConstraint,
  SteelGrade,
  BasketTabType,
} from "src/store/api/generatedApi";
import { BasketsTab } from "./basketsTab";
import { ImpuritiesTab } from "./impuritiesTab";
import { PlanId } from "components/common/boundary/PlanId";
import { Period } from "hooks/periodIndex";
import { useTenantData } from "contexts/tenant";

export type MixDetailsProps = {
  mix: number;
  name: string;
  summary: OptimisationResultSetSummary;
  steelGrades: (SteelGrade & { id: SteelGradesId })[];
  elements: Record<number, ChemicalElement>;
  planId: PlanId;
  period: Period;
  chemistryGroupId: number;
  defaultTargetNumberOfBaskets: number;
  maximumFailureRate: number;
  chemicalConstraints: ChemicalConstraint[];
};

type BasketTabProps = {
  basketTabType: BasketTabType;
  planId: PlanId;
  chemistryGroupId: number;
  mix: number;
  summary: OptimisationResultSetSummary;
  name: string;
  period: Period;
};

const filterForChefGroup =
  (chefGroupName: string, period: Period, mix?: number) =>
  (item: MixBasketSummary | MixMaterialBasketSummary) => {
    return (
      item.chef_group_name === chefGroupName &&
      period === item.period &&
      (mix === undefined || item.mix_number === mix)
    );
  };

const BasketTab = ({
  basketTabType,
  planId,
  chemistryGroupId,
  mix,
  summary,
  name,
  period,
}: BasketTabProps) => {
  switch (basketTabType) {
    case "layering_table":
      return (
        <LayeringTab
          planId={planId}
          groupId={chemistryGroupId}
          mixNumber={mix}
        />
      );
    case "basket_plot":
      return (
        <BasketsTab
          mixBasketsSummary={summary.mix_basket_summary.filter(
            filterForChefGroup(name, period, mix)
          )}
          mixMaterialBasketsSummary={summary.mix_material_basket_summary.filter(
            filterForChefGroup(name, period, mix)
          )}
        />
      );
  }
};

export const MixDetails = ({
  chemistryGroupId,
  name,
  mix,
  summary,
  steelGrades,
  elements,
  planId,
  defaultTargetNumberOfBaskets,
  maximumFailureRate,
  chemicalConstraints,
  period,
}: MixDetailsProps) => {
  const { t } = useTenantTranslation();
  const { basket_tab_type: basketTabType } = useTenantData();

  const [tabIndex, setTabIndex] = React.useState(0);

  const tabSpecs: TabSpec[] = [
    {
      title: t("baskets"),
      key: "baskets",
      content: (
        <BasketTab
          basketTabType={basketTabType}
          planId={planId}
          chemistryGroupId={chemistryGroupId}
          mix={mix}
          summary={summary}
          name={name}
          period={period}
        />
      ),
    },
    {
      title: t("impurities"),
      key: "impurities",
      content: (
        <ImpuritiesTab
          planId={planId}
          chefGroupId={chemistryGroupId}
          mixNumber={mix}
          period={period}
        />
      ),
    },
    {
      title: t("similarChargedRecipes"),
      key: "similarChargedRecipes",
      content: (
        <SimilarChargedRecipesTab
          planId={planId}
          mixNumber={mix}
          period={period}
          chefGroupId={chemistryGroupId}
          chefGroupName={name}
          steelGrades={steelGrades}
          mixMaterialBasketSummary={summary.mix_material_basket_summary}
          mixMaterialSummary={summary.mix_material_summary}
          mixSummary={summary.mix_summary}
        />
      ),
    },
    {
      title: t("properties"),
      key: "properties",
      content: (
        <ChefGroupProperties
          maximumFailureRate={maximumFailureRate}
          defaultTargetNumberOfBaskets={defaultTargetNumberOfBaskets}
          chemicalConstraints={chemicalConstraints}
          steelGrades={steelGrades}
          elements={elements}
          chefGroupId={chemistryGroupId}
        />
      ),
    },
  ];

  return (
    <>
      <ChefGroupSummary
        mixSummary={summary.mix_summary}
        chefGroupName={name}
        period={period}
      />

      <TitledTabs
        tabIndex={tabIndex}
        onChange={setTabIndex}
        tabSpecs={tabSpecs}
      />
    </>
  );
};
