import {
  Box,
  ButtonBase,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { ChevronRight, Done, InfoRounded } from "@mui/icons-material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";

import { SessionSummary } from "store/api/generatedApi";
import { useNavigateToSearch } from "hooks/navigation";
import { useFormatLocalDateString } from "hooks/useFormatLocalDate";
import { useTenantTranslation } from "hooks/formatters";
import { SearchStatusChip } from "../search/common/SearchStatus";
import { SessionOptionsButton } from "./SessionOptionsButton";
import { useCanDeploy } from "hooks/useCanDeploy";
import { defaultPlanName } from "../plan/name";
import { useSyncedResultsState } from "contexts/search/results";
import { sorted } from "helpers";

type Props = { sessions: SessionSummary[] };

export const SearchesTable = ({ sessions }: Props) => {
  const { t } = useTenantTranslation();

  const formatLocalDate = useFormatLocalDateString("datetime");

  const canDeploy = useCanDeploy();

  const columns = React.useMemo((): GridColDef[] => {
    const baseColumns: GridColDef[] = [
      {
        field: "name",
        headerName: t("name"),
        flex: 2,
        renderCell: SessionNameButtonCell,
      },
      {
        field: "created_at",
        headerName: t("createdAtHeader"),
        flex: 1,
        display: "flex",
        valueFormatter: (value) => formatLocalDate(value),
      },
      {
        field: "user_email",
        headerName: t("createdByHeader"),
        display: "flex",
        flex: 1,
      },
      {
        field: "history",
        cellClassName: "history",
        headerName: t("history"),
        display: "flex",
        flex: 1,
        renderCell: HistoryCell,
      },
      {
        field: "options",
        headerName: "",
        width: 45,
        renderCell: SessionOptionsButtonCell,
        sortable: false,
      },
    ];
    if (canDeploy) {
      const deploymentColumn = {
        field: "deployment",
        headerName: t("deployedHeader"),
        flex: 1,
        renderCell: DeployedCell,
      };
      return [
        ...baseColumns.slice(0, 1),
        deploymentColumn,
        ...baseColumns.slice(1),
      ];
    } else {
      return baseColumns;
    }
  }, [canDeploy, t, formatLocalDate]);

  return (
    <DataGridPremium
      rows={sessions}
      columns={columns}
      getRowId={(row: SessionSummary) => row.session_id}
      getRowHeight={() => "auto"}
      sx={{
        ".history": {
          padding: 0,
        },
      }}
    />
  );
};

const DeployedCellTooltip = ({
  planId,
  username,
  deployedAt,
  email,
  searchId,
}: {
  planId: number;
  username: string;
  deployedAt: string;
  email: string;
  searchId: number;
  sessionId: number;
}) => {
  const { results } = useSyncedResultsState(searchId, 0, false, () => null);
  const theme = useTheme();
  const { t } = useTenantTranslation();

  const children = (() => {
    if (results.status === "success") {
      const planName = defaultPlanName(planId);
      return (
        <>
          {t("userDeployedPlanAt", { username, email, planName, deployedAt })}
        </>
      );
    } else {
      return (
        <>
          <Skeleton
            sx={{
              bgcolor: theme.palette.common.white,
              width: 200,
              padding: 0,
            }}
          />
          <Skeleton
            sx={{
              bgcolor: theme.palette.common.white,
              width: 50,
              padding: 0,
            }}
          />
          <Skeleton
            sx={{
              bgcolor: theme.palette.common.white,
              width: 150,
              padding: 0,
              display: "inline-block",
            }}
          />
          <Skeleton
            sx={{
              bgcolor: theme.palette.common.white,
              width: 100,
              padding: 0,
            }}
          />
        </>
      );
    }
  })();

  return children;
};

const DeployedCell = ({
  row: session,
}: GridRenderCellParams<SessionSummary>) => {
  const theme = useTheme();
  const formatLocalDate = useFormatLocalDateString("datetime");

  const { deployment_history } = session;
  if (deployment_history.length > 0) {
    const lastDeployed = sorted(deployment_history, (item) =>
      new Date(item.deployed_at).getTime()
    )[0];

    if (lastDeployed) {
      return (
        <Box
          alignItems="center"
          gap={0.5}
          sx={{
            display: "flex",
            py: 1,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Done sx={{ fill: theme.palette.success.main }} />
          <Typography
            variant="body1"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              wordBreak: "unset",
            }}
          >
            {formatLocalDate(lastDeployed.deployed_at)}
          </Typography>
          <Tooltip
            title={
              <DeployedCellTooltip
                searchId={lastDeployed.search_id}
                sessionId={lastDeployed.session_id}
                planId={lastDeployed.plan_id}
                username={lastDeployed.username}
                deployedAt={formatLocalDate(lastDeployed.deployed_at)}
                email={lastDeployed.email}
              />
            }
          >
            <InfoRounded sx={{ marginLeft: "auto" }} />
          </Tooltip>
        </Box>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const HistoryCell = ({
  row: session,
}: GridRenderCellParams<SessionSummary>) => {
  const { searches_failed, searches_succeeded, searches_running } = session;

  const chip = useMemo(() => {
    if (
      searches_failed === 0 &&
      searches_succeeded === 0 &&
      searches_running === 0
    ) {
      return <SearchStatusChip searchStatus="not_started" />;
    } else if (searches_failed === 0 && searches_succeeded === 0) {
      return <SearchStatusChip searchStatus="running" />;
    } else {
      return (
        <>
          {searches_succeeded > 0 && (
            <SearchStatusChip
              searchStatus="success"
              count={searches_succeeded}
            />
          )}
          {searches_failed > 0 && (
            <SearchStatusChip searchStatus="failure" count={searches_failed} />
          )}
          {searches_running > 0 && (
            <SearchStatusChip searchStatus="running" count={searches_running} />
          )}
        </>
      );
    }
  }, [searches_failed, searches_running, searches_succeeded]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        paddingY: 0.5,
        paddingX: 1,
        columnGap: 1,
        rowGap: 0.5,
      }}
    >
      {chip}
    </Box>
  );
};

const SessionNameButtonCell = ({
  row: session,
}: GridRenderCellParams<SessionSummary>) => {
  const navigate = useNavigateToSearch();
  return (
    <ButtonBase
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      onClick={() => navigate(session.last_search_id)}
    >
      <Typography variant="button">{session.name}</Typography>
      <ChevronRight />
    </ButtonBase>
  );
};

const SessionOptionsButtonCell = ({
  row: session,
}: GridRenderCellParams<SessionSummary>) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <SessionOptionsButton session={session} />
    </Box>
  );
};
