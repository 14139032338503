import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MixMaterialLimitId, MixMaterialLimit } from "contexts/search/context";
import { typeSafeObjectKeys } from "src/utils/typeSafeObjectKeys";
import { MaterialLimitCoefficientCreate } from "../api/generatedApi";

export type ReduxMixMaterialLimit = Omit<MixMaterialLimit, "coefficients"> & {
  coefficients: MaterialLimitCoefficientCreate[];
};

export type CreateReduxMixMaterialLimit = Omit<
  ReduxMixMaterialLimit,
  "id" | "coefficients_signature"
>;

export type MixMaterialLimitsState = {
  slug: null | string;
  mixMaterialLimitSetId: null | number;
  mixMaterialLimits: Record<MixMaterialLimitId, ReduxMixMaterialLimit>;
};

const initialState: MixMaterialLimitsState = {
  slug: null,
  mixMaterialLimitSetId: null,
  mixMaterialLimits: {},
};

const castMixMaterialLimitId = (int: number) => int as MixMaterialLimitId;

export const mixMaterialLimitsSlice = createSlice({
  name: "mixMaterialLimits",
  initialState,
  reducers: {
    dump(
      state,
      action: PayloadAction<
        [number, Record<MixMaterialLimitId, ReduxMixMaterialLimit>]
      >
    ) {
      const [mixMaterialLimitSetId, mixMaterialLimits] = action.payload;
      state.mixMaterialLimits = mixMaterialLimits;
      state.mixMaterialLimitSetId = mixMaterialLimitSetId;
      state.slug = JSON.stringify(mixMaterialLimits);
    },
    add(state, { payload }: PayloadAction<CreateReduxMixMaterialLimit>) {
      const id = castMixMaterialLimitId(
        Math.max(...typeSafeObjectKeys(state.mixMaterialLimits)) + 1
      );
      state.mixMaterialLimits[id] = {
        ...payload,
        id,
        coefficients_signature: "",
      };
    },
    edit(state, { payload }: PayloadAction<ReduxMixMaterialLimit>) {
      state.mixMaterialLimits[payload.id] = payload;
    },
    remove(state, action: PayloadAction<MixMaterialLimitId>) {
      delete state.mixMaterialLimits[action.payload];
    },
  },
});

export const { ...mixMaterialActions } = mixMaterialLimitsSlice.actions;
