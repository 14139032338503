import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { StatusIndicator } from "./StatusIndicator";
import { getMassStatus, roundToNearestTonne } from "./utils";
import {
  PlanCraneMixBasket,
  useListAllMaterialsQuery,
} from "store/api/generatedApi.tsx";
import { useTenantTranslation } from "hooks/formatters.tsx";
import { useTenant } from "hooks/settings.tsx";
import { useNumberSerialiser } from "hooks/serialisers/numbers.ts";

interface BasketLoadingProps {
  basketData: PlanCraneMixBasket;
  currentMasses: Record<string, number>;
  onMassUpdate: (material: string, mass: number) => void;
  onSubmit: () => void;
  onAddMaterial: (materialName: string) => void;
}

export const BasketLoading: React.FC<BasketLoadingProps> = ({
  basketData,
  currentMasses,
  onMassUpdate,
  onSubmit,
  onAddMaterial,
}) => {
  const tenant = useTenant();
  const { data: materials = [] } = useListAllMaterialsQuery({
    tenantName: tenant,
  });
  const { t } = useTenantTranslation();
  const { format, parse } = useNumberSerialiser({ decimalPlaces: 0 });

  const handleMassChange = (material: string, increment: number) => {
    const currentMass = currentMasses[material] ?? 0;
    const newMass = roundToNearestTonne(
      Math.max(0, currentMass + increment / 1000) // Convert kg to tonnes
    );
    onMassUpdate(material, newMass);
  };
  const isBasketNonEmpty =
    Object.values(currentMasses).reduce(
      (acc, currentMass) => acc + currentMass,
      0
    ) > 0;

  const handleDirectMassInput = (material: string, value: string) => {
    // Remove any non-numeric characters except decimal point
    const result = parse(value);

    // If empty or just a decimal point, set to 0
    if (!result.valid) {
      onMassUpdate(material, 0);
      return;
    }
    onMassUpdate(material, result.value);
  };

  const handleMassInputBlur = (material: string, value: string) => {
    const result = parse(value);
    if (!result.valid) {
      onMassUpdate(material, 0);
    } else {
      onMassUpdate(material, roundToNearestTonne(result.value));
    }
  };

  // Get list of materials not yet in the current masses OR basket data
  const otherMaterials = materials.filter((material) => {
    const isInCurrentMasses = material.name in currentMasses;
    const isInBasketData = material.name in basketData.material_masses;
    const isBasketLoaded = material.addition_location === "basket";
    return !isInCurrentMasses && !isInBasketData && isBasketLoaded;
  });

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {t("basket")} {basketData.basket_number}
        </Typography>

        {/* Material Selection Tray */}
        <Paper
          elevation={3}
          sx={{
            p: 2,
            mb: 3,
            backgroundColor: "grey.100",
            display: otherMaterials.length > 0 ? "block" : "none",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("otherMaterials")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              maxHeight: "150px",
              overflowY: "auto",
            }}
          >
            {otherMaterials
              .sort((a, b) => a.ordering - b.ordering)
              .map((material) => (
                <Chip
                  key={material.id}
                  label={material.name}
                  onClick={() => onAddMaterial(material.name)}
                  sx={{
                    fontSize: "1.4rem",
                    p: 2.5,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "primary.contrastText",
                    },
                  }}
                />
              ))}
          </Box>
        </Paper>

        {/* Current Materials */}
        <Grid container spacing={3}>
          {Object.entries(currentMasses).map(([material, currentMass]) => {
            const targetMass = basketData.material_masses[material] ?? 0;
            const status = getMassStatus(currentMass, targetMass);

            return (
              <Grid item xs={12} key={material}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      minWidth: "200px",
                    }}
                  >
                    <StatusIndicator status={status} />
                    <Typography variant="h4">{material}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                      flexGrow: 1,
                      justifyContent: "right",
                    }}
                  >
                    <Typography sx={{ minWidth: "100px" }} variant="h4">
                      {t("target")}: {format(targetMass)}t
                    </Typography>

                    <ButtonGroup
                      size="large"
                      variant="outlined"
                      color="primary"
                    >
                      <Button
                        onClick={() => handleMassChange(material, -5000)}
                        sx={{ px: 2, fontSize: "1.5rem" }}
                      >
                        -5t
                      </Button>
                      <Button
                        onClick={() => handleMassChange(material, -3000)}
                        sx={{ px: 2, fontSize: "1.5rem" }}
                      >
                        -3t
                      </Button>
                      <Button
                        onClick={() => handleMassChange(material, -1000)}
                        sx={{ px: 2, fontSize: "1.5rem" }}
                      >
                        -1t
                      </Button>
                    </ButtonGroup>

                    <TextField
                      value={format(currentMass)}
                      onChange={(e) =>
                        handleDirectMassInput(material, e.target.value)
                      }
                      onBlur={(e) =>
                        handleMassInputBlur(material, e.target.value)
                      }
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*\\.?[0-9]*",
                        style: {
                          textAlign: "center",
                          fontSize: "1.5rem",
                          padding: "0.5rem",
                        },
                      }}
                      sx={{
                        width: "60px",
                        "& input": {
                          textAlign: "center",
                        },
                      }}
                      variant="outlined"
                    />

                    <ButtonGroup
                      size="large"
                      variant="outlined"
                      color="primary"
                    >
                      <Button
                        onClick={() => handleMassChange(material, 1000)}
                        sx={{ px: 2, fontSize: "1.5rem" }}
                      >
                        +1t
                      </Button>
                      <Button
                        onClick={() => handleMassChange(material, 3000)}
                        sx={{ px: 2, fontSize: "1.5rem" }}
                      >
                        +3t
                      </Button>
                      <Button
                        onClick={() => handleMassChange(material, 5000)}
                        sx={{ px: 2, fontSize: "1.5rem" }}
                      >
                        +5t
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography variant="h3">
                {t("totalMass")}:{" "}
                {format(
                  Object.values(currentMasses).reduce((a, b) => a + (b ?? 0), 0)
                )}
                t
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onSubmit}
                disabled={!isBasketNonEmpty}
                sx={{ py: 2, px: 4 }}
              >
                {t("submitBasket")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
