import { SummaryTagEnum } from "src/store/api/generatedApi";

type TranslationKey =
  | "notEnoughAvailableScrap"
  | "challengingForScrapyard"
  | "usesNonDefaultBaskets";

export const getSummaryTagEnumTranslationKey = (
  summaryTag: SummaryTagEnum
): TranslationKey => {
  switch (summaryTag) {
    case "challenging_for_scrapyard":
      return "challengingForScrapyard";
    case "not_enough_available_scrap":
      return "notEnoughAvailableScrap";
    case "uses_non_default_baskets":
      return "usesNonDefaultBaskets";
  }
};
