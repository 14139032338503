import {
  OptimisationSlackVariableUsage,
  OptimisationStatus,
  Plan,
  SummaryTag,
} from "store/api/generatedApi";
import { useNumberSerialiser } from "hooks/serialisers/numbers";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";

export type PlanCardData = {
  planId: number;
  planIndex: number;
  planName: string | null;
  searchId: number;
  tags: SummaryTag[];
  metrics: PlanMetrics;
  optimisationSlackVariableUsage: OptimisationSlackVariableUsage[];
  optimisationStatus: OptimisationStatus;
};

export type PlanMetrics = {
  specificPrice: number;
  scrapMass: number;
  requiredObtainableCost: number;

  safetyStock: { material: string; mass: number }[];
  drainedMaterials: { material: string; mass: number }[];
};

export const planCards = (plans: Plan[]): PlanCardData[] => {
  return plans
    .map(planCard)
    .filter(
      (plan, index, allPlans) =>
        allPlans.map(planCardKey).indexOf(planCardKey(plan)) === index
    );
};

const planCard = (plan: Plan): PlanCardData => {
  const { display_cost_per_tonne, scrap_mass } =
    plan.summary.first_period_summary;
  const safetyStockMaterials = plan.summary.material_summary.filter(
    (material) => material.highlight_safety_stock
  );
  const stockToDrainMaterials = plan.summary.material_summary.filter(
    (material) => material.highlight_stock_to_drain
  );
  const requiredObtainableCost = plan.summary.obtainable_summary
    .map((item) => item.required_obtainable * item.specific_price)
    .reduce((left, right) => left + right, 0);

  return {
    optimisationStatus: plan.optimisation_status,
    planId: plan.id,
    planName: null,
    planIndex: plan.plan_index,
    searchId: plan.search_id,
    tags: plan.summary.tags,
    metrics: {
      specificPrice: display_cost_per_tonne,
      scrapMass: scrap_mass,
      requiredObtainableCost,
      safetyStock: safetyStockMaterials.map((material) => ({
        material: material.material,
        mass: material.inventory_post_planned,
      })),
      drainedMaterials: stockToDrainMaterials.map((material) => ({
        material: material.material,
        mass: material.consumption_planned,
      })),
    },
    optimisationSlackVariableUsage: plan.optimisation_slack_variable_usage,
  };
};

const planCardKey = (card: PlanCardData): string =>
  // Convert everything to arrays to ensure consistent ordering
  JSON.stringify([
    card.metrics.specificPrice,
    card.metrics.scrapMass,
    card.metrics.requiredObtainableCost,
    card.metrics.safetyStock.map((item) => [item.material, item.mass]),
    card.metrics.drainedMaterials.map((item) => [item.material, item.mass]),
    card.tags.map((tag) => [tag.tag, tag.details, tag.sentiment]),
    card.optimisationStatus,
    card.optimisationSlackVariableUsage.map(
      ({ usage, details, period, slack_variable }) => [
        usage,
        details,
        period,
        slack_variable,
      ]
    ),
  ]);

export const usePlanCardMetrics = (
  metrics: PlanMetrics
): { title: string; value: string }[] => {
  const { t } = useTenantTranslation();
  const priceSerialiser = useNumberSerialiser({ decimalPlaces: 2 });
  const integerSerialiser = useNumberSerialiser({ decimalPlaces: 0 });
  const units = useUnitsFormatter(false);

  return [
    {
      title: t("tappedPrice"),
      value: `${units("cost").trim()}${priceSerialiser.format(
        metrics.specificPrice
      )}/${units("mass").trim()}`,
    },
    ...(metrics.safetyStock.length > 0
      ? metrics.safetyStock.map((material) => ({
          title: `${material.material} ${t("inventoryRemainingResultSummary")}`,
          value: `${integerSerialiser.format(material.mass)}${units("mass")}`,
        }))
      : [
          {
            title: t("scrapMassResultSummary"),
            value: `${integerSerialiser.format(metrics.scrapMass)}${units(
              "mass"
            ).trim()}`,
          },
          {
            title: t("necessaryObtainablePriceResultSummary"),
            value: `${units("cost").trim()}${priceSerialiser.format(
              metrics.requiredObtainableCost
            )}`,
          },
        ]),
    ...metrics.drainedMaterials.map((material) => ({
      title: `${material.material} ${t("scrapConsumedSummary")}`,
      value: `${integerSerialiser.format(material.mass)}${units("mass")}`,
    })),
  ];
};
