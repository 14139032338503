import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import React from "react";
import {
  MaterialLimit,
  MaterialLimitsEditor,
} from "components/common/forms/MaterialLimitsEditor";

type EditorDialogProps = {
  doClose: () => void;
  materialLimit: MaterialLimit;
  title: string;
  onSubmit: (materialLimit: MaterialLimit) => void;
};

export const BasketMaterialLimitEditorDialog = ({
  doClose,
  onSubmit,
  title,
  materialLimit: initialMaterialLimit,
}: EditorDialogProps) => {
  const { t } = useTenantTranslation();
  const [materialLimit, setMaterialLimit] =
    React.useState<MaterialLimit>(initialMaterialLimit);
  const canSubmit =
    materialLimit.name !== "" &&
    Object.values(materialLimit.coefficients).length > 0;

  return (
    <Dialog open onClose={doClose} maxWidth="xl">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <MaterialLimitsEditor
          onChange={setMaterialLimit}
          materialLimit={materialLimit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={doClose} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (canSubmit) {
              onSubmit(materialLimit);
              doClose();
            }
          }}
          disabled={!canSubmit}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
