import { Button, Stack, Typography, Slider } from "@mui/material";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { sorted } from "helpers";
import {
  roundToDecimalPlaces,
  useNumberSerialiser,
} from "hooks/serialisers/numbers";
import { useRef, useEffect, useState, RefObject } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useVideoLabels } from "./loading";
import { LabelView } from "./label";
import { useNotifyStatus } from "contexts/status";
import { ViewRailcars } from "./railcars";
import { useGetVideoUrlQuery } from "src/store/api/generatedApi";
import { SelectVideo } from "./selectVideo";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTenant } from "hooks/settings";

export type Label = { start: number; end: number } & (
  | { type: "lift"; material: string | null }
  | { type: "drop" }
  | { type: "obscured" }
);

export const VideoLabellingPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const notifyStatus = useNotifyStatus();
  const tenant = useTenant();

  const [height, setHeight] = useState(1200);
  const [speed, setSpeed] = useState(15);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [statusText, setStatusText] = useState<string | null>(null);
  const renderedStatusText =
    currentTime >= duration && duration > 0 ? "Vido finshd" : statusText;

  const heightSerialiser = useNumberSerialiser();
  const speedSerialiser = useNumberSerialiser({ max: 15 });

  const controls = useVideoControls(videoRef.current, speed);

  const nothing = () => null;

  useHotkeys("up", controls.toggle ?? nothing);
  useHotkeys("left", controls.skipBackwardReplayTime ?? nothing);
  useHotkeys("shift+left", controls.skipBackwardRealTime ?? nothing);
  useHotkeys("right", controls.skipForwardReplayTime ?? nothing);
  useHotkeys("shift+right", controls.skipForwardRealTime ?? nothing);

  const [videoKey, setVideoKey] = useState<string | null>(null);
  const [camera, setCamera] = useState<number>(2);
  const videoUrl = mapLoadedUnpack(
    loadedEndpoint(
      useGetVideoUrlQuery(
        videoKey === null ? skipToken : { tenantName: tenant, videoKey, camera }
      )
    ),
    (url) => url
  );

  const [events, , { saveLabels, addLabel, deleteLabel, deleteLastLabel }] =
    useVideoLabels();

  useMaterialHotkey("u", null, addLabel, videoRef, setStatusText);

  useDropHotkey(addLabel, videoRef, setStatusText);
  useObscuredHotkey(addLabel, videoRef, setStatusText);

  useHotkeys("backspace", deleteLastLabel);
  useHotkeys("shift+backspace", () => {
    const label = deleteLastLabel();
    if (label !== null && videoRef.current !== null) {
      (controls.pause ?? nothing)();
      videoRef.current.currentTime = label.start;
    }
  });

  // Update the current time and duration as the video plays
  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      const updateProgress = () => setCurrentTime(video.currentTime);
      const updateDuration = () => setDuration(video.duration);

      video.addEventListener("timeupdate", updateProgress);
      video.addEventListener("loadedmetadata", updateDuration);

      return () => {
        video.removeEventListener("timeupdate", updateProgress);
        video.removeEventListener("loadedmetadata", updateDuration);
      };
    }
  }, [videoRef]);

  // Handle slider change for seeking
  const handleProgressChange = (_: unknown, value: number | number[]) => {
    if (videoRef.current && typeof value === "number") {
      videoRef.current.currentTime = value;
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} p={1}>
        <SelectVideo
          videoKey={videoKey}
          setVideoKey={setVideoKey}
          camera={camera}
          setCamera={setCamera}
          saveLabels={() => {
            if (videoKey !== null) {
              void saveLabels(
                videoKey,
                undefined,
                duration > 0 ? duration : undefined
              );
            }
          }}
        />

        <Stack direction="row" alignItems="center" gap={1} sx={{ width: 150 }}>
          <Typography>Height</Typography>
          <ValidatedTextField
            value={height}
            setValue={setHeight}
            serialiser={heightSerialiser}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} sx={{ width: 150 }}>
          <Typography>Speed</Typography>
          <ValidatedTextField
            value={speed}
            setValue={setSpeed}
            serialiser={speedSerialiser}
          />
        </Stack>

        {videoKey === null ? (
          <Button disabled>Railcars</Button>
        ) : (
          <ViewRailcars timestamp={videoKey} />
        )}
      </Stack>

      {/* Video progress bar */}
      <Stack direction="row" alignItems="center" gap={2} p={2}>
        <Slider
          min={0}
          max={duration}
          value={currentTime}
          onChange={handleProgressChange}
          onFocus={(event) => event.target.blur()}
          aria-labelledby="video-progress-slider"
        />
        <Typography>
          {roundToDecimalPlaces(currentTime, 2)}s /{" "}
          {roundToDecimalPlaces(duration, 2)}s
        </Typography>
      </Stack>
      <Stack sx={{ height: height }} direction="row" justifyContent="center">
        <video
          ref={videoRef}
          src={videoUrl ?? undefined}
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            transform: "rotate(180deg)",
            transformOrigin: "center",
          }}
          onEnded={() => notifyStatus({ type: "info", text: "Video finished" })}
        />
        {renderedStatusText === null ? null : (
          <Typography
            sx={{
              position: "absolute",
              fontSize: 140,
              top: "50%",
              transform: "translateY(-50%)",
              color: "red",
            }}
          >
            {renderedStatusText}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" sx={{ width: "100%" }}>
        <Stack sx={{ width: "50%" }}>
          {sorted(events, (event) => -event.start).map((event) => (
            <LabelView
              key={event.start}
              label={event}
              deleteLabel={deleteLabel}
              seekTimestamp={(timestamp) => {
                if (videoRef.current) {
                  videoRef.current.currentTime = timestamp;
                }
              }}
            />
          ))}
        </Stack>

        <Stack sx={{ width: 0.5 }}>
          <Aliases
            addLabel={addLabel}
            videoRef={videoRef}
            setStatusText={setStatusText}
          />
        </Stack>
      </Stack>
    </>
  );
};

const Aliases = ({
  addLabel,
  videoRef,
  setStatusText,
}: {
  addLabel: (label: Label) => void;
  videoRef: RefObject<HTMLVideoElement>;
  setStatusText: (statusText: string | null) => void;
}) => {
  const [aliases, setAliases] = useLocalStorageState<{ [key: string]: string }>(
    "videoLabellingAliases",
    {
      s: "Shredded",
      w: "Turnings",
      a: "#1 Heavy Melt",
      r: "Rebar",
      q: "Tire Wire",
      d: "Reclaim",
      x: "Cast Iron Borings",
      z: "Tank Tracks",
      e: "#2 Heavy Melt",
      c: "Billets",
      f: "P & S",
      t: "Wire Bundles",
      v: "Flashings",
      g: "Rail Crops",
      b: "#1 & #2 Heavy Melting Steel",
    }
  );

  return (
    <>
      <ValidatedTextField<{ [key: string]: string }>
        value={aliases}
        setValue={(newValue) => setAliases(newValue)}
        serialiser={{
          format: (value) => JSON.stringify(value, null, 2),
          parse: (text) => ({
            valid: true,
            value: JSON.parse(text) as { [key: string]: string },
          }),
        }}
        textFieldProps={{ multiline: true }}
      />

      {Object.entries(aliases).map(([hotkey, material]) => (
        <Alias
          key={hotkey}
          hotkey={hotkey}
          material={material}
          addLabel={addLabel}
          videoRef={videoRef}
          setStatusText={setStatusText}
        />
      ))}
    </>
  );
};

const Alias = ({
  hotkey,
  material,
  addLabel,
  videoRef,
  setStatusText,
}: {
  hotkey: string;
  material: string;
  addLabel: (label: Label) => void;
  videoRef: RefObject<HTMLVideoElement>;
  setStatusText: (statusText: string | null) => void;
}) => {
  useMaterialHotkey(hotkey, material, addLabel, videoRef, setStatusText);

  return null;
};

const useLocalStorageState = <T,>(key: string, value: T) => {
  const storedValue = localStorage.getItem(key);
  const [state, setState] = useState<T>(
    storedValue === null ? value : (JSON.parse(storedValue) as T)
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState] as const;
};

const useVideoControls = (video: HTMLVideoElement | null, speed: number) => {
  useEffect(() => {
    if (video) {
      video.playbackRate = speed;
    }
  }, [video, speed]);

  if (video) {
    return {
      play: () => {
        video.playbackRate = speed;
        void video.play();
      },
      pause: () => video.pause(),
      toggle: () => {
        if (video.paused) {
          video.playbackRate = speed;
          void video.play();
        } else {
          video.pause();
        }
      },
      skipForwardRealTime: () => {
        video.currentTime += 1;
        video.pause();
      },
      skipBackwardRealTime: () => {
        video.currentTime -= 1;
        video.pause();
      },
      skipForwardReplayTime: () => {
        video.currentTime += speed;
        video.pause();
      },
      skipBackwardReplayTime: () => {
        video.currentTime -= speed;
        video.pause();
      },
    };
  } else {
    return {};
  }
};

const useMaterialHotkey = (
  hotkey: string,
  material: string | null,
  addLabel: (label: Label) => void,
  videoRef: RefObject<HTMLVideoElement>,
  setStatusText: (statusText: string | null) => void
) => {
  const [start, setStart] = useState<number | null>(null);

  useKeyPressEffects(
    hotkey,
    () => {
      setStart(getTimestamp(videoRef));
      setStatusText(material ?? "Unknown");
    },
    () => {
      if (start !== null) {
        addLabel({
          start,
          end: getTimestamp(videoRef),
          type: "lift",
          material,
        });
        setStart(null);
        setStatusText(null);
      }
    }
  );
};

const useDropHotkey = (
  addLabel: (label: Label) => void,
  videoRef: RefObject<HTMLVideoElement>,
  setStatusText: (statusText: string | null) => void
) => {
  const [start, setStart] = useState<number | null>(null);

  return useKeyPressEffects(
    "down",
    () => {
      setStart(getTimestamp(videoRef));
      setStatusText("Dropping");
    },
    () => {
      if (start !== null) {
        addLabel({ start, end: getTimestamp(videoRef), type: "drop" });
        setStart(null);
        setStatusText(null);
      }
    }
  );
};

const useObscuredHotkey = (
  addLabel: (label: Label) => void,
  videoRef: RefObject<HTMLVideoElement>,
  setStatusText: (statusText: string | null) => void
) => {
  const [start, setStart] = useState<number | null>(null);

  return useKeyPressEffects(
    "/",
    () => {
      setStart(getTimestamp(videoRef));
      setStatusText("Obscured");
    },
    () => {
      if (start !== null) {
        addLabel({ start, end: getTimestamp(videoRef), type: "obscured" });
        setStart(null);
        setStatusText(null);
      }
    }
  );
};

const useKeyPressEffects = (
  key: string,
  onDown: () => void,
  onUp: () => void
) => {
  const [pressed, setPressed] = useState(false);
  useHotkeys(
    key,
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!pressed) {
        onDown();
      }
      setPressed(true);
    },
    { keydown: true }
  );
  useHotkeys(
    key,
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onUp();
      setPressed(false);
    },
    { keyup: true }
  );
};

const getTimestamp = (videoRef: RefObject<HTMLVideoElement>): number => {
  if (videoRef.current) {
    return roundToDecimalPlaces(videoRef.current.currentTime, 2);
  } else {
    return -1;
  }
};
