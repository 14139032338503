import { FlattenedChefGroupBasketMaterialLimit } from "contexts/search/context";
import { ChefGroupBasketMaterialLimitSetCreate } from "src/store/api/generatedApi";

export const groupFlattenedChefGroupBasketMaterialLimits = (
  flattendChefGroupBasketMaterialLimits: FlattenedChefGroupBasketMaterialLimit[]
): ChefGroupBasketMaterialLimitSetCreate => {
  return {
    default: true,
    chef_group_basket_material_limits: flattendChefGroupBasketMaterialLimits
      .filter((flattened, index, allFlattened) => {
        return (
          index ===
          allFlattened
            .map((flattened) => flattened.chef_group_basket_material_limit_id)
            .indexOf(flattened.chef_group_basket_material_limit_id)
        );
      })
      .map((flattened) => {
        return {
          chef_group_id: flattened.chef_group_id,
          basket_material_limit: {
            coefficients: flattened.coefficients,
            name: flattened.name,
            hardness: flattened.hardness,
            bounds: flattendChefGroupBasketMaterialLimits
              .filter(
                ({ chef_group_id, basket_material_limit_id }) =>
                  flattened.chef_group_id === chef_group_id &&
                  flattened.basket_material_limit_id ===
                    basket_material_limit_id
              )
              .map(
                ({
                  max_mass,
                  soft_max_mass,
                  min_mass,
                  soft_min_mass,
                  basket_id,
                }) => {
                  return {
                    max_mass,
                    min_mass,
                    soft_max_mass,
                    soft_min_mass,
                    basket_id,
                  };
                }
              ),
          },
        };
      }),
  };
};
