import { createSelector } from "@reduxjs/toolkit";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import React from "react";
import {
  chefGroupBasketMaterialLimitSetSlice,
  CreateFlattenedChefGroupBasketMaterialLimit,
} from "../slices/chefGroupBasketMaterialLimits";
import {
  ChefGroupBasketMaterialLimit,
  ChefGroupBasketMaterialLimitId,
  FlattenedChefGroupBasketMaterialLimit,
} from "contexts/search/context";
import { groupBasketMaterialLimitsByBasket } from "src/utils/groupBasketMaterialLimitsByBasket";

export const selectChefGroupBasketMaterialLimits = createSelector(
  [
    (state: RootState) =>
      state.chefGroupBasketMaterialLimits.chefGroupBasketMaterialLimits,
    (_: RootState, chefGroupId?: number) => chefGroupId,
    (_: RootState, __?: number, coefficientsSignature?: string) =>
      coefficientsSignature,
  ],
  (chefGroupBasketMaterialLimits, chefGroupId, coefficientsSignature) =>
    Object.values(chefGroupBasketMaterialLimits).filter(
      ({ chef_group_id, coefficients_signature }) =>
        (chefGroupId !== undefined ? chef_group_id === chefGroupId : true) &&
        (coefficientsSignature !== undefined
          ? coefficients_signature === coefficientsSignature
          : true)
    )
);

export const useChefGroupBasketMaterialLimits = (
  chefGroupId?: number,
  coefficientsSignature?: string
) => {
  return useAppSelector((state) => {
    return selectChefGroupBasketMaterialLimits(
      state,
      chefGroupId,
      coefficientsSignature
    );
  });
};

export const useChefGroupBasketMaterialLimitsGroupedByBasket = (
  chefGroupId?: number
) => {
  return groupBasketMaterialLimitsByBasket(
    useChefGroupBasketMaterialLimits(chefGroupId)
  );
};

export const useSlug = () =>
  useAppSelector((state) => state.chefGroupBasketMaterialLimits.slug);

export const useHaveChefGroupBasketMaterialLimitsMutated = () => {
  const chefGroupBasketMaterialLimits = useAppSelector(
    (state) => state.chefGroupBasketMaterialLimits.chefGroupBasketMaterialLimits
  );
  const slug = useSlug();
  return (
    slug !== null && JSON.stringify(chefGroupBasketMaterialLimits) !== slug
  );
};

export const useChefGroupBasketMaterialLimitsSlice = () => {
  const dispatch = useAppDispatch();
  const actions = React.useMemo(() => {
    return {
      dump: (
        id: number,
        chefGroupBasketMaterialLimits: Record<
          ChefGroupBasketMaterialLimitId,
          ChefGroupBasketMaterialLimit
        >
      ) => {
        dispatch(
          chefGroupBasketMaterialLimitSetSlice.actions.dump([
            id,
            chefGroupBasketMaterialLimits,
          ])
        );
      },
      edit: (
        editChefGroupBasketMaterialLimit: FlattenedChefGroupBasketMaterialLimit
      ) => {
        dispatch(
          chefGroupBasketMaterialLimitSetSlice.actions.edit(
            editChefGroupBasketMaterialLimit
          )
        );
      },
      add: (
        newChefGroupBasketMaterialLimit: CreateFlattenedChefGroupBasketMaterialLimit
      ) => {
        dispatch(
          chefGroupBasketMaterialLimitSetSlice.actions.add(
            newChefGroupBasketMaterialLimit
          )
        );
      },
    };
  }, [dispatch]);
  return actions;
};

export const useChefGroupBasketMaterialLimitsArray = () => {
  return Object.values(useChefGroupBasketMaterialLimits());
};
