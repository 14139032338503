import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ValidatedTextField } from "components/common/inputs/validatedTextField";
import { LoadedContent } from "components/common/loading/loadedContent";
import { FullWidthPlot, PlotConfig } from "components/common/plots";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { useTenant } from "hooks/settings";
import { loadedEndpoint } from "models/loaded";
import { useState } from "react";
import { useGetRailcarsQuery } from "src/store/api/generatedApi";

export const ViewRailcars = ({ timestamp }: { timestamp: string | null }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Railcars</Button>

      {open ? (
        <RailcarsModal timestamp={timestamp} close={() => setOpen(false)} />
      ) : null}
    </>
  );
};

export const RailcarsModal = ({
  close,
  timestamp,
}: {
  timestamp: string | null;
  close: () => void;
}) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(timestamp ?? "");
  const tenant = useTenant();

  const data = loadedEndpoint(
    useGetRailcarsQuery({ tenantName: tenant, key: currentTimestamp })
  );

  return (
    <Dialog open onClose={close} fullWidth maxWidth="lg">
      <DialogTitle>Railcars</DialogTitle>
      <ValidatedTextField
        label="Key"
        value={currentTimestamp}
        setValue={setCurrentTimestamp}
        serialiser={useStringSerialiser()}
      />
      <DialogContent sx={{ height: 600 }}>
        <LoadedContent data={data}>
          {(plot) => (
            <FullWidthPlot
              plotConfig={JSON.parse(JSON.parse(plot) as string) as PlotConfig}
            />
          )}
        </LoadedContent>
      </DialogContent>
    </Dialog>
  );
};
