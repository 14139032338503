import { Chip } from "@mui/material";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { UnitType } from "src/store/api/generatedApi";

type Props = {
  minMass: number | null;
  maxMass: number | null;
  name: string;
  onClick: () => void;
};

export const MaterialLimitChip = ({
  minMass,
  maxMass,
  name,
  onClick,
}: Props) => {
  const units = useUnitsFormatter(false);
  const { t } = useTenantTranslation();
  const expression = formatMaterialLimits({ minMass, maxMass }, units);
  const label = `${t(name)} ${expression}`;

  return (
    <Chip
      onClick={onClick}
      sx={{ mr: 1, mb: 1 }}
      label={label}
      size="small"
      key={label}
    />
  );
};

export const formatMaterialLimits = (
  {
    minMass,
    maxMass,
  }: { minMass: number | undefined | null; maxMass: number | undefined | null },
  units: (type: UnitType) => string
): string => {
  const mass_part =
    minMass != undefined &&
    minMass > 0 &&
    maxMass != undefined &&
    minMass !== maxMass
      ? `${minMass.toFixed(0)}-${maxMass.toFixed(0)}${units("mass")}`
      : maxMass != undefined && minMass === maxMass
      ? `= ${maxMass.toFixed(0)}${units("mass")}`
      : maxMass != undefined
      ? `≤ ${maxMass.toFixed(0)}${units("mass")}`
      : minMass != undefined && minMass > 0
      ? `≥ ${minMass.toFixed(0)}${units("mass")}`
      : null;

  return mass_part ?? "";
};
