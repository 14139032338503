import { Box, Grid, Typography } from "@mui/material";

export type Props = {
  title?: string;
  actionButton?: React.ReactNode;
  children?: React.ReactNode;
};

export const AccountPageLayout = ({ title, actionButton, children }: Props) => {
  return (
    <Grid container sx={{ px: 3, py: 8, height: "100%" }}>
      <Grid item xs={1} />
      <Grid
        item
        xs={10}
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          {title == null ? null : <Typography variant="h1">{title}</Typography>}
          {actionButton ?? null}
        </Box>
        {children}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};
