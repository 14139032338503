import React from "react";
import { Box, CircularProgress, Grow, Stack, Typography } from "@mui/material";
import { useTenant } from "hooks/settings";

import {
  useGetLastDropWeightDatetimeQuery,
  useGetLastDropWeightsQuery,
} from "src/store/api/generatedApi";

export const DropWeightPage = () => {
  const tenantName = useTenant();
  const lastDatetimeQuery = useGetLastDropWeightDatetimeQuery({ tenantName });

  if (lastDatetimeQuery.data) {
    return <DropWeightPageBody lastDatetime={lastDatetimeQuery.data} />;
  } else {
    return null;
  }
};

type Props = {
  lastDatetime: string;
};

export const DropWeightPageBody = ({ lastDatetime }: Props) => {
  const tenantName = useTenant();
  const [skip, setSkip] = React.useState(false);
  const latestDropQuery = useGetLastDropWeightsQuery(
    {
      tenantName,
      lastDatetime,
    },
    {
      pollingInterval: 10000,
      skip,
    }
  );

  React.useEffect(() => {
    if (!skip && latestDropQuery.isSuccess && latestDropQuery.data.length > 0) {
      setSkip(true);
    }
  }, [latestDropQuery, skip, setSkip]);

  return (
    <Stack sx={{ margin: "0 auto" }}>
      {latestDropQuery.data ? (
        latestDropQuery.data.map((datum) => (
          <Grow in key={datum.drop_datetime}>
            <Box>
              <Typography component="span" variant="h3">
                Heat&nbsp;
                <Typography variant="body1">{datum.heat_id}</Typography>
              </Typography>
              <Typography component="span" variant="h3">
                Bucket&nbsp;
                <Typography variant="body1">
                  {datum.charge_bucket_id}
                </Typography>
              </Typography>
              <Typography component="span" variant="h3">
                Weight&nbsp;
                <Typography variant="body1">{datum.drop_weight}</Typography>
              </Typography>
              <Typography component="span" variant="h3">
                Material&nbsp;
                <Typography variant="body1">{datum.operator_layer}</Typography>
              </Typography>
            </Box>
          </Grow>
        ))
      ) : (
        <CircularProgress />
      )}
    </Stack>
  );
};
