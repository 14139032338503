import { mapLoaded, mapLoadedUnpack } from "models/loaded";
import { usePlan } from "src/contexts/plan";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { Box, Collapse, Stack } from "@mui/material";
import { PlanName, useDefaultPlanName } from "./name";
import { PlanActionButtons } from "./topButtons/planActionButtons";
import { BackToResults } from "./topButtons/BackToResults";
import { useTenant, useUsesDri } from "hooks/settings";
import {
  useHasDefaultSets,
  useSearch,
  useServerIds,
} from "contexts/search/provider";
import { SummaryStat } from "../common/summaryStat";
import { ConstraintsAlert } from "../common/alerts/Constraints";
import { useNavigateToSearch } from "hooks/navigation";
import { InputsOutdatedAlert } from "../common/alerts/inputsOutdated";
import { usePeriodIndex } from "hooks/periodIndex";
import { PlanTagChip } from "./planTagChip";
import { InputsSuboptimalAlert } from "components/common/alerts/inputsSuboptimal";

type Props = {
  planId: number;
  showExtendedSummary: boolean;
};

export const PlanSummary = ({ planId, showExtendedSummary }: Props) => {
  const { plan } = usePlan(planId);
  const defaultName = useDefaultPlanName()(
    plan.status === "success" ? plan.data.plan_index : 0
  );
  const isSuboptimal =
    plan.status === "success"
      ? ["failed_suboptimal", "succeeded_suboptimal"].includes(
          plan.data.optimisation_status
        )
      : false;

  const {
    results: { isStale },
    setSearchId,
  } = useSearch();
  const { searchId } = useServerIds();
  const navigateToSearch = useNavigateToSearch();
  const tenantName = useTenant();

  const usesDri = useUsesDri();
  const periodTab = usePeriodIndex();

  const hasDefaultSets = useHasDefaultSets();

  return (
    <LoadedContent
      data={mapLoaded(plan, (p) => {
        const periodSummary = p.summary.period_summary.find(
          (period_summary) => period_summary.period === periodTab
        );
        if (periodSummary) {
          return periodSummary;
        } else {
          return null;
        }
      })}
    >
      {(summary) => {
        return (
          <>
            <Stack gap={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: "center" }}
                >
                  <Box>
                    <BackToResults />
                  </Box>
                  <PlanName
                    planName={defaultName}
                    defaultName={defaultName}
                    variant="h2"
                  />
                </Stack>
                <PlanActionButtons />
              </Box>
              {isStale ? (
                <Box sx={{ ml: 6 }}>
                  <InputsOutdatedAlert />
                </Box>
              ) : isSuboptimal && plan.status === "success" ? (
                <Box sx={{ ml: 6 }}>
                  <InputsSuboptimalAlert
                    previousTimeLimit={plan.data.time_limit}
                  />
                </Box>
              ) : null}

              {searchId !== null &&
              hasDefaultSets.status === "success" &&
              !hasDefaultSets.data ? (
                <Box sx={{ ml: 6 }}>
                  <ConstraintsAlert
                    searchId={searchId}
                    tenantName={tenantName}
                    onRefresh={(searchId) => {
                      setSearchId(searchId);
                      navigateToSearch(searchId);
                    }}
                  />
                </Box>
              ) : null}
            </Stack>

            <Collapse in={showExtendedSummary}>
              <Box sx={{ mt: 1, ml: 6 }}>
                <Stack sx={{ mb: 1.5 }} flexDirection="row" gap={0.5}>
                  {mapLoadedUnpack(plan, (p) =>
                    p.summary.tags.filter((tag) => tag.period === periodTab)
                  )?.map((tag) => (
                    <PlanTagChip
                      tag={tag.tag}
                      details={tag.details}
                      key={`tag-chip-${tag.tag}`}
                    />
                  ))}
                </Stack>
                <Stack direction="row" gap={4}>
                  <SummaryStat
                    label="tappedMass"
                    value={
                      summary === null ? null : summary.display_produced_mass
                    }
                    decimalPlaces={0}
                    units="mass"
                  />
                  <SummaryStat
                    label="tappedPrice"
                    value={
                      summary === null ? null : summary.display_cost_per_tonne
                    }
                    decimalPlaces={2}
                    units="specific_cost"
                  />
                  <SummaryStat
                    label="heats"
                    value={summary === null ? null : summary.num_heats}
                    decimalPlaces={0}
                    units={null}
                  />
                  <SummaryStat
                    label="scrap"
                    value={summary === null ? null : summary.scrap_mass}
                    decimalPlaces={0}
                    units="mass"
                  />
                  {usesDri ? (
                    <SummaryStat
                      label="dri"
                      value={summary === null ? null : summary.dri_mass}
                      decimalPlaces={0}
                      units="mass"
                    />
                  ) : null}
                  <SummaryStat
                    label="yield"
                    value={
                      summary === null ? null : summary.display_yield_percent
                    }
                    decimalPlaces={1}
                    units="yield"
                    hideDivider
                  />
                </Stack>
              </Box>
            </Collapse>
          </>
        );
      }}
    </LoadedContent>
  );
};
