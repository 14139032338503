export const roundToNearestTonne = (value: number): number => {
  return Math.round(value);
};

export const getMassStatus = (
  current: number,
  target: number
): "under" | "over" | "complete" => {
  const tolerance = 0.5; // 1t tolerance
  if (Math.abs(current - target) <= tolerance) return "complete";
  return current < target ? "under" : "over";
};
