import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Chip, Tooltip } from "@mui/material";

import { SummaryTagEnum } from "store/api/generatedApi";
import { useTenantTranslation } from "hooks/formatters";
import { getSummaryTagEnumTranslationKey } from "src/utils/getSummaryTagEnumTranslationKey";
import { getSummaryTagEnumDescriptionTranslationKey } from "src/utils/getSummaryTagEnumDescriptionTranslationKey";

type Props = {
  tag: SummaryTagEnum;
  details: string[];
};

export const PlanTagChip = ({ tag, details }: Props) => {
  const { t } = useTenantTranslation();
  const tooltip =
    tag !== "uses_non_default_baskets" ? (
      <div>
        {t(getSummaryTagEnumDescriptionTranslationKey(tag))}
        <ul>
          {details.map((detail) => (
            <li key={`${tag}-${detail}`}>{t(detail)}</li>
          ))}
        </ul>
      </div>
    ) : null;
  return (
    <Chip
      sx={{
        "& .MuiChip-deleteIcon": {
          color: "action.active",
          "&:hover": {
            color: "action.active",
          },
        },
      }}
      label={t(getSummaryTagEnumTranslationKey(tag))}
      size="small"
      onDelete={() => null}
      deleteIcon={
        tooltip ? (
          <Tooltip title={tooltip}>
            <InfoRoundedIcon />
          </Tooltip>
        ) : (
          <span />
        )
      }
    />
  );
};
