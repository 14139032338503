import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TitledTabs } from "src/components/common/titledTabs";
import { ProvideExperimentalSearch, useExperimentalSearch } from "./provider";
import { SyncedJsonEditor } from "src/components/debug/syncedJsonEditor";
import { LoadingButton } from "@mui/lab";
import { useSyncExperimentalSearch } from "./hooks";
import {
  useGetExperimentalSearchesQuery,
  useGetExperimentalSessionsQuery,
  useGetResultQuery,
} from "src/store/api/generatedApi";
import { loadedEndpoint, mapLoadedUnpack } from "models/loaded";
import { useTenant } from "hooks/settings";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { SearchResultsContent } from "src/components/search/output/SearchResults";
import { useImportMixAllocationFromSpreadsheet } from "./result/apply";
import { useStringSerialiser } from "hooks/serialisers/strings";
import { ValidatedTextField } from "src/components/common/inputs/validatedTextField";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { currentTimestamp } from "./timestamps";
import { InputsTab } from "./inputs/timeline";
import { useId } from "./helpers";
import { useOptimiserInputs } from "./processing/resolve";
import {
  useApplyMixResultsFromPlan,
  useRunExperimentalSearch,
} from "./processing/actions";
import { sorted } from "helpers";
import { skipToken } from "@reduxjs/toolkit/query/react";

export const Page = () => {
  const [tab, setTab] = useState(0);
  const [id, setId] = useState(useId());

  return id === null ? (
    <PickExperimentalSearch setId={setId} />
  ) : (
    <ProvideExperimentalSearch id={id} setId={setId}>
      <TitledTabs
        rightComponent={<ActionButtons setId={setId} />}
        tabSpecs={[
          { title: "Inputs", key: "inputs", content: <InputsTab /> },
          { title: "Optimiser", key: "optimiser", content: <OptimiserTab /> },
          { title: "Results", key: "results", content: <ResultsTab /> },
          // { title: "Plan", key: "plan", content: <PlanTab /> },
          { title: "Debug", key: "debug", content: <DebugTab /> },
        ]}
        tabIndex={tab}
        onChange={setTab}
      />
    </ProvideExperimentalSearch>
  );
};

const PickExperimentalSearch = ({ setId }: { setId: (id: number) => void }) => {
  const tenant = useTenant();
  const [sessionId, setSessionId] = useState<number | null>(null);

  const sessions = loadedEndpoint(
    useGetExperimentalSessionsQuery({ tenantName: tenant })
  );
  const searches = loadedEndpoint(
    useGetExperimentalSearchesQuery(
      sessionId === null ? skipToken : { tenantName: tenant, sessionId }
    )
  );

  return (
    <>
      <NewExperimentalSearch setId={setId} />

      {sessionId === null ? (
        <LoadedContent data={sessions}>
          {(loadedSessions) => (
            <Stack alignItems="flex-start">
              {sorted(loadedSessions, (session) => session.session_name)
                .reverse()
                .map((session) => (
                  <Button
                    key={session.session_id}
                    onClick={() => setSessionId(session.session_id)}
                  >
                    {session.session_name}
                  </Button>
                ))}
            </Stack>
          )}
        </LoadedContent>
      ) : (
        <>
          <Button variant="contained" onClick={() => setSessionId(null)}>
            Back to sessions
          </Button>
          <LoadedContent data={searches}>
            {(loadedSearches) => (
              <Stack alignItems="flex-start">
                {loadedSearches.map((search) => (
                  <Button key={search.id} onClick={() => setId(search.id)}>
                    {search.id}
                  </Button>
                ))}
              </Stack>
            )}
          </LoadedContent>
        </>
      )}
    </>
  );
};

export const ActionButtons = ({
  setId,
}: {
  setId: (id: number | null) => void;
}) => {
  return (
    <Stack direction="row">
      <Button onClick={() => setId(null)} variant="contained" sx={{ m: 1 }}>
        Back
      </Button>
      <NewExperimentalSearch setId={setId} />
    </Stack>
  );
};

export const NewExperimentalSearch = ({
  setId,
}: {
  setId: (id: number) => void;
}) => {
  const [syncExperimentalSearch, { loading }] = useSyncExperimentalSearch();

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      sx={{ m: 1 }}
      onClick={() => {
        void syncExperimentalSearch({
          context_id: null,
          session_id: null,
          parent_id: null,
          search_id: null,
          inputs: {
            production: [],
            obtainable: [],
            inventory: {
              name: null,
              inventory: [],
              timestamp: currentTimestamp(),
            },
            periods: [],
            markers: [],
          },
        })
          .then((result) => setId(result.id))
          .catch(console.error);
      }}
    >
      New
    </LoadingButton>
  );
};

export const RunExperimentalSearch = ({
  polling,
  onClick,
}: {
  polling: boolean;
  onClick: () => void;
}) => {
  const [runExperimentalSearch, { loading }] = useRunExperimentalSearch();

  return (
    <LoadingButton
      variant="contained"
      loading={loading || polling}
      sx={{ m: 1 }}
      onClick={() => {
        runExperimentalSearch();
        onClick();
      }}
    >
      Run
    </LoadingButton>
  );
};

export const OptimiserTab = () => {
  const optimiserInputs = useOptimiserInputs();

  return (
    <Stack gap={2} sx={{ p: 2 }}>
      <Typography variant="h1">Production plan</Typography>
      <LoadedContent data={optimiserInputs}>
        {(loaded) => (
          <DataGridPremium
            rows={loaded.production.flatMap((item) =>
              item.production_plan.map((steelGradeItem) => ({
                ...item,
                ...steelGradeItem,
                id: `${item.period} ${steelGradeItem.steel_grade_id} ${item.source_uuid}`,
              }))
            )}
            columns={[
              { field: "period", flex: 1 },
              { field: "source_uuid", flex: 1 },
              { field: "steel_grade_id", flex: 1 },
              { field: "num_heats", flex: 1 },
            ]}
          />
        )}
      </LoadedContent>

      <Typography variant="h1">Obtainable bundles</Typography>
      <LoadedContent data={optimiserInputs}>
        {(loaded) => (
          <DataGridPremium
            rows={loaded.obtainable.flatMap((item) =>
              item.obtainable_bundles.map((bundle) => ({
                ...item,
                ...bundle,
                id: `${item.period} ${bundle.material_id} ${item.source_uuid}`,
              }))
            )}
            columns={[
              { field: "period", flex: 1 },
              { field: "source_uuid", flex: 1 },
              { field: "material_id", flex: 1 },
              { field: "uuid", flex: 1 },
              { field: "min_obtainable", flex: 1 },
              { field: "max_obtainable", flex: 1 },
              { field: "specific_price", flex: 1 },
            ]}
          />
        )}
      </LoadedContent>
    </Stack>
  );
};

export const ResultsTab = () => {
  const searchId = mapLoadedUnpack(
    useExperimentalSearch().client,
    ({ search_id }) => search_id
  );
  const [sheetName, setSheetName] = useState("");
  const serialiser = useStringSerialiser();

  const [importMixAllocationFromFile, { loading }] =
    useImportMixAllocationFromSpreadsheet(sheetName);

  const [polling, setPolling] = useState(false);

  return (
    <Box>
      <RunExperimentalSearch
        polling={polling}
        onClick={() => setPolling(true)}
      />

      {searchId === null ? (
        <Typography>Waiting to run</Typography>
      ) : (
        <>
          <Typography>Results</Typography>
          <ResultsContent
            searchId={searchId}
            polling={polling}
            setPolling={setPolling}
          />
        </>
      )}

      <div
        style={{ backgroundColor: "lightblue", width: 800, height: 100 }}
        onPaste={(event) => {
          if (event.clipboardData.files.length > 0) {
            importMixAllocationFromFile(event.clipboardData.files[0]!);
          }
        }}
      >
        <Typography>
          Type the sheet name into the text box then click on this box and paste
          a file into it
        </Typography>
        <ValidatedTextField
          value={sheetName}
          setValue={setSheetName}
          serialiser={serialiser}
        />
        {loading ? <Typography>Importing...</Typography> : null}
      </div>
    </Box>
  );
};

const ResultsContent = ({
  searchId,
  polling,
  setPolling,
}: {
  searchId: number;
  polling: boolean;
  setPolling: (polling: boolean) => void;
}) => {
  const [importFromPlan, { loading }] = useApplyMixResultsFromPlan();
  const [loadedSearch, setLoadedSearch] = useState<number | null>(null);

  const results = loadedEndpoint(
    useGetResultQuery(
      { tenantName: useTenant(), searchId },
      {
        refetchOnMountOrArgChange: true,
        pollingInterval: polling ? 2000 : undefined,
      }
    )
  );

  useEffect(() => {
    if (
      results.status === "success" &&
      results.data.status !== "incomplete" &&
      results.data.search_id !== loadedSearch
    ) {
      setPolling(false);
      setLoadedSearch(results.data.search_id);
    }
  }, [results]);

  return (
    <LoadedContent data={results}>
      {(loadedResults) => (
        <>
          {loading ? <LinearProgress /> : null}
          <SearchResultsContent
            running={false}
            plans={loadedResults.plans}
            errors={
              loadedResults.failure_reason == null
                ? []
                : [loadedResults.failure_reason]
            }
            onClickPlan={(planCard) => importFromPlan(planCard.planId)}
          />
        </>
      )}
    </LoadedContent>
  );
};

export const DebugTab = () => {
  const search = useExperimentalSearch();

  return (
    <Box>
      <SyncedJsonEditor state={search} />
    </Box>
  );
};

// export const PlanTab = () => {
//   const mixes = useCandidateMixes();

//   return (
//     <LoadedContent data={mixes}>
//       {(loadedMixes) => (
//         <ApprovePlanContent
//           toNextState={() => null}
//           candidateMixes={loadedMixes}
//           showSteelGrades
//           decimalPlaces={1}
//         />
//       )}
//     </LoadedContent>
//   );
// };
