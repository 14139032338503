import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTenantTranslation } from "hooks/formatters";
import { useState } from "react";
import { useGetRunConditions } from "./RunConditions";
import { useMiscParams, useRunSearch } from "contexts/search/provider";
import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useIsAdmin } from "hooks/settings";
import { useHaveMaterialLimitsUpdated } from "src/store/hooks/materialLimits";
import { MaterialLimitUpdatesNotSubmitted } from "components/common/dialogs/MaterialLimitUpdatesNotSubmitted";

export const RunSearchButtonGroup = () => {
  const { t } = useTenantTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSubmitUpdatesPromptOpen, setIsSubmitUpdatesPropOpen] =
    React.useState(false);

  const isAdmin = useIsAdmin();
  const [, setMiscParams] = useMiscParams();
  const runConditions = useGetRunConditions();
  const runSearch = useRunSearch();

  const options = [t("searchPlans"), t("longOptimisation")];

  const haveMaterialLimitsUpdated = useHaveMaterialLimitsUpdated();

  const handleStartRunSearch = React.useCallback(() => {
    if (haveMaterialLimitsUpdated) {
      setIsSubmitUpdatesPropOpen(true);
    } else {
      runSearch();
    }
  }, [haveMaterialLimitsUpdated, runSearch]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (index === 0) {
      // Turn off deep search
      setMiscParams((current) => ({
        ...current,
        optimisation_time_limit: null,
      }));
    }
    if (index === 1) {
      // Turn on deep search
      setMiscParams((current) => ({
        ...current,
        optimisation_time_limit: 120,
      }));
    }
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (runConditions === null) {
    return <LoadingButton variant="contained" loading />;
  }
  if (!isAdmin) {
    return (
      <Button variant="contained" onClick={runSearch}>
        {options[selectedIndex]}
      </Button>
    );
  }
  return (
    <>
      <MaterialLimitUpdatesNotSubmitted
        isOpen={isSubmitUpdatesPromptOpen}
        doClose={() => setIsSubmitUpdatesPropOpen(false)}
      />
      <Tooltip
        title={runConditions.areRunConditionsMet ? "" : t("searchPlansText")}
      >
        <ButtonGroup
          variant="contained"
          disabled={!runConditions.areRunConditionsMet}
          disableElevation
        >
          <Button onClick={handleStartRunSearch}>
            {options[selectedIndex]}
          </Button>
          <Button size="small" onClick={handleToggle} sx={{ p: 0 }}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </Tooltip>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 0.5 }}
        MenuListProps={{ disablePadding: true }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}
            sx={{ px: 2, py: 1 }}
          >
            <Typography variant="button">{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
