import { useTenant } from "hooks/settings";
import i18next from "i18next";
import { createContext, ReactNode, useContext } from "react";
import { languages } from "src/constants";
import { Tenant, useGetTenantMetadataQuery } from "src/store/api/generatedApi";

const TenantContext = createContext<Tenant | undefined>(undefined);

export const ProvideTenantData = ({ children }: { children: ReactNode }) => {
  const tenantName = useTenant();
  const { data: tenantData, isFetching } = useGetTenantMetadataQuery({
    tenantName,
  });

  if (tenantData !== undefined) {
    const translationOverrides = Object.fromEntries(
      Object.entries(tenantData.translation_overrides).map(([key, value]) => [
        key,
        "$t(" + value + ")",
      ])
    );
    Object.keys(languages).forEach((language) =>
      i18next.addResourceBundle(language, tenantName, translationOverrides)
    );
  }

  return tenantData === undefined || isFetching ? null : (
    <TenantContext.Provider value={tenantData}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenantData = (): Tenant => {
  const tenantData = useContext(TenantContext);

  if (tenantData === undefined) {
    throw new Error("No tenant data has been provided");
  } else {
    return tenantData;
  }
};
