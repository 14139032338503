import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { ObtainableBundleItem } from "store/api/generatedApi";
import {
  SearchMaterialPhysics,
  SearchMaterials,
} from "contexts/search/context";
import { Box, IconButton, useTheme } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useHandleTableMutations } from "./useHandleTableMutations";
import { useSortDataGridByMaterial } from "src/components/common/dataGrid/utilities";
import { NumberCell } from "src/components/common/dataGrid/NumberCell";
import { MaterialPropertyViewCell } from "../common/MaterialPropertyViewCell";

type Props = {
  materials: SearchMaterials;
  materialPhysics: SearchMaterialPhysics;
  obtainableBundleItems: ObtainableBundleItem[];
  selectedItemIds: string[];
  setSelectedItemIds: (selectedItemsIds: string[]) => void;
  readOnly?: boolean;
};

export type AvailabilityRow = ObtainableBundleItem & {
  material_name: string;
};

export const AvailabilitiesTable = ({
  materials,
  materialPhysics,
  obtainableBundleItems,
  selectedItemIds,
  setSelectedItemIds,
  readOnly,
}: Props) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);

  const { materialNameColumnSortComparator, materialNameSortModel } =
    useSortDataGridByMaterial(materials);

  const { handleAddItem, handleUpdateItem } =
    useHandleTableMutations(materials);

  const availabilityRows: AvailabilityRow[] = obtainableBundleItems.map(
    (item) => {
      const material = materials.byIndex.find(
        (material) => material.id === item.material_id
      );
      return {
        ...item,
        material_name: material?.name ?? "",
      };
    }
  );

  const materialOptions = materials.byIndex.map((material) => material.name);

  const columns: GridColDef<AvailabilityRow>[] = [
    {
      field: "material_name",
      headerName: t("material"),
      editable: !readOnly,
      sortable: true,
      flex: 1,
      type: "singleSelect",
      valueOptions: materialOptions,
      sortComparator: materialNameColumnSortComparator,
      renderCell: (params) => (
        <MaterialPropertyViewCell
          name={params.row.material_name}
          id={params.row.material_id}
        />
      ),
      display: "flex",
    },
    {
      field: "label",
      headerName: t("label"),
      editable: !readOnly,
      sortable: false,
      cellClassName: "scrap-label",
      flex: 1,
      display: "flex",
    },
    {
      field: "min_obtainable",
      headerName: `${t("minObtainable")} (${units("mass").trim()})`,
      description: t("minObtainableDescription"),
      editable: !readOnly,
      sortable: false,
      flex: 1,
      renderCell: (params) => <NumberCell value={params.row.min_obtainable} />,
      display: "flex",
    },
    {
      field: "max_obtainable",
      headerName: `${t("maxObtainable")} (${units("mass").trim()})`,
      description: t("maxObtainableDescription"),
      editable: !readOnly,
      sortable: false,
      flex: 1,
      renderCell: (params) => <NumberCell value={params.row.max_obtainable} />,
      display: "flex",
    },
    {
      field: "specific_price",
      headerName: `${t("price")} (${units("specific_cost").trim()})`,
      editable: !readOnly,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <NumberCell value={params.row.specific_price} decimalPlaces={2} />
      ),
      display: "flex",
    },
    {
      field: "estimated_yielded_price",
      headerName: `${t("estimatedYieldedPrice")} (${units(
        "specific_cost"
      ).trim()})`,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <NumberCell
          value={
            params.row.specific_price === null
              ? null
              : params.row.specific_price /
                (materialPhysics.byId[params.row.material_id]!.yield_percent /
                  100)
          }
          decimalPlaces={2}
        />
      ),
      display: "flex",
    },
    {
      field: "arrival_probability",
      headerName: `${t("arrivalProbability")}` + " (%)",
      editable: !readOnly,
      sortable: false,
      flex: 1,
      valueFormatter: (value) => (value ?? 1) * 100,
      renderCell: (params) => (
        <NumberCell
          min={0}
          defaultValue={100}
          value={params.row.arrival_probability}
          decimalPlaces={0}
          scaleBy={100}
        />
      ),
      display: "flex",
    },
  ];
  return (
    <Box
      // Prevent the modal from closing when pressing Escape key while in edit mode in the data grid
      onKeyDown={(e) => e.key === "Escape" && e.stopPropagation()}
    >
      <DataGridPremium
        rows={availabilityRows}
        columns={columns}
        getRowId={(row: AvailabilityRow) => row.uuid}
        processRowUpdate={handleUpdateItem}
        checkboxSelection={!readOnly}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedItemIds(newRowSelectionModel.map(String));
        }}
        rowSelectionModel={selectedItemIds}
        sx={{
          height: 500,
          "& .MuiDataGrid-cell--editing input": {
            textAlign: "right",
            paddingX: 1,
            ...theme.typography.body1Mono,
          },
          "& .scrap-label input": {
            textAlign: "left",
            paddingX: 1,
            ...theme.typography.body1,
          },
        }}
        initialState={{ sorting: materialNameSortModel }}
        cellSelection
        ignoreValueFormatterDuringExport
      />
      {!readOnly && (
        <IconButton
          size="medium"
          color="primary"
          onClick={handleAddItem}
          sx={{ my: 1 }}
        >
          <AddCircleOutline fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};
