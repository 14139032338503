import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { PlanCraneMixes } from "store/api/generatedApi.tsx";
import { useTenantTranslation } from "hooks/formatters.tsx";

interface GradeSelectionProps {
  data: PlanCraneMixes;
  onGradeSelect: (grade: string) => void;
}

interface GradesByGroup {
  [copperGroup: string]: string[];
}

export const GradeSelection: React.FC<GradeSelectionProps> = ({
  data,
  onGradeSelect,
}) => {
  const { t } = useTenantTranslation();

  // Organize grades by copper group
  const gradesByGroup = Object.values(data.mixes).reduce<GradesByGroup>(
    (acc, mix) => {
      const copperGroup = mix.copper_group;
      if (!acc[copperGroup]) {
        return { ...acc, [copperGroup]: [...mix.steel_grades] };
      }
      return {
        ...acc,
        [copperGroup]: Array.from(
          new Set([...(acc[copperGroup] ?? []), ...mix.steel_grades])
        ),
      };
    },
    {}
  );

  // Sort copper groups numerically and grades alphabetically within each group
  const sortedGroups = Object.entries(gradesByGroup)
    .sort(([groupA], [groupB]) => parseInt(groupA) - parseInt(groupB))
    .map(
      ([group, grades]) =>
        [group, [...grades].sort((a, b) => a.localeCompare(b))] as [
          string,
          string[],
        ]
    );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        {t("selectSteelGrade")}
      </Typography>

      {sortedGroups.map(([copperGroup, grades], groupIndex) => (
        <Box key={copperGroup} sx={{ mb: 6 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 3,
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              px: 3,
              py: 2,
              borderRadius: 1,
            }}
          >
            <Typography variant="h5">Cu {Number(copperGroup)}</Typography>
          </Box>

          <Grid container spacing={2}>
            {grades.map((grade) => (
              <Grid item xs={12} sm={6} md={4} key={grade}>
                <Card
                  onClick={() => onGradeSelect(grade)}
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      bgcolor: "action.hover",
                      transform: "scale(1.02)",
                    },
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 2,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontWeight: 500 }}
                    >
                      {grade}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {groupIndex < sortedGroups.length - 1 && <Divider sx={{ mt: 4 }} />}
        </Box>
      ))}
    </Box>
  );
};
