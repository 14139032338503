import { typeSafeObjectEntries } from "./typeSafeObjectEntries";
import { typeSafeObjectFromEntries } from "./typeSafeObjectFromEntries";

export const typeSafeObjectMap = <
  Key extends string | number | symbol,
  Value1,
  Value2,
>(
  object: Record<Key, Value1>,
  map: (key: Key, value: Value1) => [Key, Value2]
) => {
  return typeSafeObjectFromEntries(
    typeSafeObjectEntries(object).map(([key, value]) => {
      return map(key, value);
    })
  );
};
