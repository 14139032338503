import {
  ChefGroupBasketMaterialLimitId,
  FlattenedChefGroupBasketMaterialLimit,
} from "contexts/search/context";

export const groupBasketMaterialLimitsByBasket = (
  basketMaterialLimits: FlattenedChefGroupBasketMaterialLimit[]
) => {
  return basketMaterialLimits.reduce(
    (grouped, bound) => {
      if (!grouped[bound.basket_number]) {
        return {
          ...grouped,
          [bound.basket_number]: {
            basket_number: bound.basket_number,
            basket_id: bound.basket_id,
            basket_volume: bound.basket_volume,
            chef_group_id: bound.chef_group_id,
            chef_group_basket_material_limit_id:
              bound.chef_group_basket_material_limit_id,
            bounds: [bound],
          },
        };
      } else {
        return {
          ...grouped,
          [bound.basket_number]: {
            ...grouped[bound.basket_number]!,
            bounds: [...grouped[bound.basket_number]!.bounds, bound],
          },
        };
      }
    },
    {} as Record<
      number,
      {
        basket_id: number;
        basket_volume: number;
        basket_number: number;
        chef_group_id: number;
        chef_group_basket_material_limit_id: ChefGroupBasketMaterialLimitId;
        bounds: FlattenedChefGroupBasketMaterialLimit[];
      }
    >
  );
};
