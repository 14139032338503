import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BasketMaterialLimitId,
  ChefGroupBasketMaterialLimitId,
  createFlattenedChefGroupBasketMaterialLimitId,
  FlattenedChefGroupBasketMaterialLimit,
  FlattenedChefGroupBasketMaterialLimitId,
} from "contexts/search/context";

export type ChefGroupBasketMaterialLimitsState = {
  slug: null | string;
  chefGroupBasketMaterialLimitSetId: number | null;
  chefGroupBasketMaterialLimits: Record<
    FlattenedChefGroupBasketMaterialLimitId,
    FlattenedChefGroupBasketMaterialLimit
  >;
};

export type CreateFlattenedChefGroupBasketMaterialLimit = Omit<
  FlattenedChefGroupBasketMaterialLimit,
  | "basket_material_limit_id"
  | "id"
  | "chef_group_basket_material_limit_id"
  | "coefficients_signature"
>;

const initialState: ChefGroupBasketMaterialLimitsState = {
  slug: null,
  chefGroupBasketMaterialLimitSetId: null,
  chefGroupBasketMaterialLimits: {},
};

const castBasketMaterialLimitId = (int: number) => int as BasketMaterialLimitId;
const castChefGroupBasketMaterialLimitId = (int: number) =>
  int as ChefGroupBasketMaterialLimitId;

export const chefGroupBasketMaterialLimitSetSlice = createSlice({
  name: "chefGroupBasketMaterialLimits",
  initialState,
  reducers: {
    dump(
      state,
      action: PayloadAction<
        [
          number,
          Record<
            FlattenedChefGroupBasketMaterialLimitId,
            FlattenedChefGroupBasketMaterialLimit
          >,
        ]
      >
    ) {
      const [chefGroupBasketMaterialLimitSetId, chefGroupBasketMaterialLimits] =
        action.payload;
      state.chefGroupBasketMaterialLimits = chefGroupBasketMaterialLimits;
      state.chefGroupBasketMaterialLimitSetId =
        chefGroupBasketMaterialLimitSetId;
      state.slug = JSON.stringify(chefGroupBasketMaterialLimits);
    },
    add(
      state,
      { payload }: PayloadAction<CreateFlattenedChefGroupBasketMaterialLimit>
    ) {
      const basketMaterialLimitId = castBasketMaterialLimitId(
        Math.max(
          ...Object.values(state.chefGroupBasketMaterialLimits).map(
            ({ basket_material_limit_id }) => basket_material_limit_id
          )
        ) + 1
      );
      const chefGroupBasketMaterialLimitId = castChefGroupBasketMaterialLimitId(
        Math.max(
          ...Object.values(state.chefGroupBasketMaterialLimits).map(
            ({ chef_group_basket_material_limit_id }) =>
              chef_group_basket_material_limit_id
          )
        ) + 1
      );
      const id = createFlattenedChefGroupBasketMaterialLimitId(
        chefGroupBasketMaterialLimitId,
        basketMaterialLimitId,
        payload.basket_id
      );
      state.chefGroupBasketMaterialLimits[id] = {
        ...payload,
        id,
        basket_material_limit_id: basketMaterialLimitId,
        chef_group_basket_material_limit_id: chefGroupBasketMaterialLimitId,
        coefficients_signature: "",
      };
    },
    edit(
      state,
      {
        payload: chefGroupBasketMaterialLimit,
      }: PayloadAction<FlattenedChefGroupBasketMaterialLimit>
    ) {
      state.chefGroupBasketMaterialLimits[chefGroupBasketMaterialLimit.id] =
        chefGroupBasketMaterialLimit;
    },
  },
});
