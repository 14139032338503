import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loaded, mapLoaded, mapLoadedUnpack } from "models/loaded";
import { SyncedSearchState, useSyncedSearchState } from "./search";
import {
  SearchChemicalElements,
  SearchChemistryGroups,
  SearchMaterialChemistry,
  SearchMaterialPhysics,
  SearchMaterials,
  SearchProductGroups,
  SearchSteelGrades,
  SyncedContextData,
} from "./context";
import {
  BasketRead,
  InventoryItem,
  MiscParams,
  PhysicalParameters,
  ObtainableBundleItem,
  SyncedProductionPlan,
  SyncedSearchPeriod,
  TargetBasketItem,
  TargetInventoryItem,
} from "src/store/api/generatedApi";
import { Period } from "hooks/periodIndex";

const SearchContext = createContext<SyncedSearchState | undefined>(undefined);

const blankPeriod: SyncedSearchPeriod = {
  name: null,
  is_deployable: false,
  production_plan: { product_group_items: null, steel_grade_items: [] },
  obtainable_bundles: [],
  target_inventory: [],
  material_exclusivity: [],
  suppress_mix_material_exclusivity_constraints: false,
  suppress_min_feasible_mass_constraints: false,
  optimisation_objective_weighting: 1.0,
};

export const ProvideSearch = ({ children }: { children: ReactNode }) => {
  const value = useSyncedSearchState();
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = (): SyncedSearchState => {
  const search = useContext(SearchContext);

  if (search == null) {
    throw new Error("No search context has been provided");
  } else {
    return search;
  }
};

export const useSearchName = (): Loaded<string> =>
  mapLoaded(useSearch().parameters.client, ({ session_name }) => session_name);

export const useRunSearch = () => {
  const {
    results: { run },
    parameters: { server },
  } = useSearch();
  const serverSearchId = mapLoadedUnpack(server, ({ id }) => id);

  const [requested, setRequested] = useState(false);
  const ready = serverSearchId != null;

  // Wait for syncing to finish before running the search
  useEffect(() => {
    if (requested && ready) {
      setRequested(false);
      run(serverSearchId);
    }
  }, [requested, ready]);

  return () => setRequested(true);
};

const useSearchContext = <T,>(selector: (context: SyncedContextData) => T) =>
  mapLoaded(useSearch().context, selector);

export const useProductGroupsId = () =>
  useSearchContext(({ productGroupsId }) => productGroupsId);
export const useSteelGradesId = () =>
  useSearchContext(({ steelGradesId }) => steelGradesId);
export const useMaterialsId = () =>
  useSearchContext(({ materialsId }) => materialsId);
export const useMaterialPhysicsId = () =>
  useSearchContext(({ materialPhysicsId }) => materialPhysicsId);

export const useMaterials = (): Loaded<SearchMaterials> =>
  useSearchContext(({ materials }) => materials);

export const useMaterialPhysics = (): Loaded<SearchMaterialPhysics> =>
  useSearchContext(({ materialPhysics }) => materialPhysics);

export const useMaterialChemistry = (): Loaded<SearchMaterialChemistry> =>
  useSearchContext(({ materialChemistry }) => materialChemistry);

export const useProductGroups = (): Loaded<SearchProductGroups> =>
  useSearchContext(({ productGroups }) => productGroups);

export const useChemistryGroups = (): Loaded<SearchChemistryGroups> =>
  useSearchContext(({ chemistryGroups }) => chemistryGroups);

export const useSteelGrades = (): Loaded<SearchSteelGrades> =>
  useSearchContext(({ steelGrades }) => steelGrades);

export const useChemicalElements = (): Loaded<SearchChemicalElements> =>
  useSearchContext(({ chemicalElements }) => chemicalElements);

// export const useMixMaterialLimits = (): Loaded<MixMaterialLimit[]> =>
//   useSearchContext(({ mixMaterialLimits }) => mixMaterialLimits);

// export const useChefGroupMixMaterialLimits = (
//   chefGroupId?: number
// ): Loaded<MixMaterialLimit[]> =>
//   useSearchContext(({ mixMaterialLimits }) =>
//     mixMaterialLimits.filter(
//       ({ chef_group_id }) =>
//         (chefGroupId !== undefined && chef_group_id === chefGroupId) ||
//         chefGroupId === undefined
//     )
//   );

export const useBaskets = (): Loaded<BasketRead[]> =>
  useSearchContext(({ baskets }) => baskets);

export const useHasDefaultSets = () =>
  useSearchContext(({ hasDefaultSets }) => hasDefaultSets);

export const useMiscParams = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ misc_params }) => misc_params),
    (update: (miscParams: MiscParams) => MiscParams) =>
      setClient((current) => ({
        ...current,
        id: null,
        misc_params: update(current.misc_params),
      })),
  ] as const;
};

export const usePhysicalParameters = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ physical_parameters }) => physical_parameters),
    (update: (physicalParameters: PhysicalParameters) => PhysicalParameters) =>
      setClient((current) => ({
        ...current,
        id: null,
        physical_parameters: update(current.physical_parameters),
      })),
  ] as const;
};

export const useInventory = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ inventory }) => inventory),
    (update: (inventory: InventoryItem[]) => InventoryItem[]) =>
      setClient((current) => ({
        ...current,
        id: null,
        inventory: update(current.inventory),
      })),
  ] as const;
};

export const useTargetBaskets = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ target_baskets }) => target_baskets),
    (update: (targetBaskets: TargetBasketItem[]) => TargetBasketItem[]) =>
      setClient((current) => ({
        ...current,
        id: null,
        target_baskets: update(current.target_baskets),
      })),
  ] as const;
};

export const usePeriod = (period: Period) => {
  const { client, setClient } = useSearch().parameters;
  const periodIndex = period - 1;
  return [
    mapLoaded(client, ({ periods }) => periods[periodIndex] ?? blankPeriod),
    (update: (period: SyncedSearchPeriod) => SyncedSearchPeriod) =>
      setClient((current) => {
        const periods = [...current.periods];
        periods[periodIndex] = update(
          current.periods[periodIndex] ?? blankPeriod
        );
        return { ...current, id: null, periods };
      }),
  ] as const;
};

export const useProductionSchedule = () => {
  const [period, setPeriod] = usePeriod(1 as Period);
  return [
    mapLoaded(period, ({ production_plan }) => production_plan),
    (update: (productionPlan: SyncedProductionPlan) => SyncedProductionPlan) =>
      setPeriod((current) => ({
        ...current,
        production_plan: update(current.production_plan),
      })),
  ] as const;
};

export const useProductionPlan = (periodIndex: Period) => {
  const [period, setPeriod] = usePeriod(periodIndex);
  return [
    mapLoaded(period, ({ production_plan }) => production_plan),
    (update: (productionPlan: SyncedProductionPlan) => SyncedProductionPlan) =>
      setPeriod((current) => ({
        ...current,
        production_plan: update(current.production_plan),
      })),
  ] as const;
};

export const useObtainableBundles = (periodIndex: Period) => {
  const [period, setPeriod] = usePeriod(periodIndex);
  return [
    mapLoaded(period, ({ obtainable_bundles }) => obtainable_bundles),
    (
      update: (
        obtainableBundles: ObtainableBundleItem[]
      ) => ObtainableBundleItem[]
    ) =>
      setPeriod((current) => ({
        ...current,
        obtainable_bundles: update(current.obtainable_bundles),
      })),
  ] as const;
};

export const useTargetInventory = (period: Period) => {
  const [searchPeriod, setSearchPeriod] = usePeriod(period);
  return [
    mapLoaded(searchPeriod, ({ target_inventory }) => target_inventory),
    (
      update: (targetInventory: TargetInventoryItem[]) => TargetInventoryItem[]
    ) =>
      setSearchPeriod((current) => ({
        ...current,
        target_inventory: update(current.target_inventory),
      })),
  ] as const;
};

export const useServerIds = () => {
  const search = useSearch().parameters.server;
  return {
    searchId: mapLoadedUnpack(search, ({ id }) => id),
    sessionId: mapLoadedUnpack(search, ({ session_id }) => session_id),
  };
};

export const usePeriods = () => {
  const { client, setClient } = useSearch().parameters;
  return [
    mapLoaded(client, ({ periods }) => periods),
    (update: (periods: SyncedSearchPeriod[]) => SyncedSearchPeriod[]) =>
      setClient((current) => ({
        ...current,
        periods: update(current.periods),
      })),
  ] as const;
};
