import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { GradeSelection } from "./GradeSelection";
import { BasketLoading } from "./BasketLoading";
import {
  BasketMaterialAdditionCreate,
  useCreateBasketMaterialAdditionMutation,
  useGetPlanCraneMixesLatestQuery,
} from "store/api/generatedApi.tsx";
import { useTenant } from "hooks/settings.tsx";
import { useTenantTranslation } from "hooks/formatters.tsx";
import { v4 as uuidv4 } from "uuid";
import { captureException, captureMessage } from "@sentry/react";
import { useLocalStorage } from "hooks/useLocalStorage.ts";

interface SubmissionStatus {
  isOpen: boolean;
  message: string;
  severity: "success" | "error";
}

interface StoredMassesData {
  planId: number;
  grade: string;
  masses: Record<number, Record<string, number>>;
}

export const CranePage = () => {
  const tenant = useTenant();
  const { t } = useTenantTranslation();

  const { data, isLoading, error } = useGetPlanCraneMixesLatestQuery({
    tenantName: tenant,
  });
  const [createBasketMaterialAddition] =
    useCreateBasketMaterialAdditionMutation();
  const [selectedGrade, setSelectedGrade] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [correlationId, setCorrelationId] = useState(uuidv4());
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>({
    isOpen: false,
    message: "",
    severity: "success",
  });

  // Use local storage with a key that includes the plan ID for uniqueness
  const [storedMasses, setStoredMasses] =
    useLocalStorage<StoredMassesData | null>("craneMasses", null);

  // Derive loadedMasses from stored masses or create new
  const loadedMasses = storedMasses?.masses ?? {};

  const generateNewCorrelationId = () => {
    setCorrelationId(uuidv4());
  };

  // Initialize masses when grade is selected
  useEffect(() => {
    if (data && selectedGrade) {
      const relevantMix = Object.values(data.mixes).find((mix) =>
        mix.steel_grades.includes(selectedGrade)
      );

      if (relevantMix) {
        // Check if we have stored masses for this plan and grade
        if (
          storedMasses?.planId === data.plan_id &&
          storedMasses?.grade === selectedGrade
        ) {
          // Use stored masses
          return;
        }

        // Initialize new masses
        const initialMasses: Record<number, Record<string, number>> = {};
        relevantMix.baskets.forEach((basket) => {
          initialMasses[basket.basket_number] = Object.keys(
            basket.material_masses
          ).reduce(
            (acc, materialName) => ({
              ...acc,
              [materialName]: 0,
            }),
            {}
          );
        });

        // Store new masses
        setStoredMasses({
          planId: data.plan_id,
          grade: selectedGrade,
          masses: initialMasses,
        });
      }
    }
  }, [selectedGrade, data, setStoredMasses]);

  const handleGradeSelect = (grade: string) => {
    setSelectedGrade(grade);
    setCurrentTab(0);
    generateNewCorrelationId();
  };

  const handleMassUpdate = (
    basketNumber: number,
    material: string,
    mass: number
  ) => {
    if (!data || !selectedGrade) return;

    setStoredMasses((prev) => {
      if (
        !prev ||
        prev.planId !== data.plan_id ||
        prev.grade !== selectedGrade
      ) {
        return {
          planId: data.plan_id,
          grade: selectedGrade,
          masses: {
            [basketNumber]: { [material]: mass },
          },
        };
      }

      return {
        ...prev,
        masses: {
          ...prev.masses,
          [basketNumber]: {
            ...(prev.masses[basketNumber] ?? {}),
            [material]: mass,
          },
        },
      };
    });
  };

  const handleAddMaterial = (basketNumber: number, materialName: string) => {
    if (!data || !selectedGrade) return;

    setStoredMasses((prev) => {
      if (
        !prev ||
        prev.planId !== data.plan_id ||
        prev.grade !== selectedGrade
      ) {
        return {
          planId: data.plan_id,
          grade: selectedGrade,
          masses: {
            [basketNumber]: { [materialName]: 0 },
          },
        };
      }

      return {
        ...prev,
        masses: {
          ...prev.masses,
          [basketNumber]: {
            ...(prev.masses[basketNumber] ?? {}),
            [materialName]: 0,
          },
        },
      };
    });
  };

  const handleBasketSubmit = async (basketNumber: number) => {
    if (!data) return;

    const relevantMix = Object.values(data.mixes).find((mix) =>
      mix.steel_grades.includes(selectedGrade!)
    );

    if (!relevantMix) {
      captureMessage(
        `User attempted to submit basket for unknown steel grade: ${selectedGrade}`
      );
      return;
    }

    const originalBasket = relevantMix.baskets.find(
      (basket) => basket.basket_number === basketNumber
    );

    if (!originalBasket) {
      captureMessage(
        `User attempted to submit unknown basket ${basketNumber} for steel grade ${selectedGrade}`
      );
      return;
    }

    // Create the submission basket with current masses
    const submissionBasket: BasketMaterialAdditionCreate = {
      basket_number: basketNumber,
      material_masses: loadedMasses[basketNumber] ?? {},
      correlation_id: correlationId,
    };

    try {
      await createBasketMaterialAddition({
        tenantName: tenant,
        planId: data.plan_id,
        basketMaterialAdditionCreate: submissionBasket,
      });

      // Show success message
      setSubmissionStatus({
        isOpen: true,
        message: `Successfully submitted Basket ${basketNumber}`,
        severity: "success",
      });

      // Clear just the submitted basket's data
      setStoredMasses((prev) => {
        if (!prev) return null;
        const newMasses = { ...prev.masses };
        delete newMasses[basketNumber];
        return {
          ...prev,
          masses: newMasses,
        };
      });

      // Generate new correlation ID if this was the last basket
      if (currentTab === relevantMix.baskets.length - 1) {
        generateNewCorrelationId();
      } else {
        setCurrentTab(currentTab + 1);
      }
    } catch (error) {
      // Show error message
      setSubmissionStatus({
        isOpen: true,
        message: `Error submitting Basket ${basketNumber}: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        severity: "error",
      });
      captureException(error);
    }
  };

  const handleCloseSnackbar = () => {
    setSubmissionStatus((prev) => ({ ...prev, isOpen: false }));
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          Error loading plan data. Please refresh the page.
        </Alert>
      </Box>
    );
  }

  if (!data) return null;

  if (!selectedGrade) {
    return <GradeSelection data={data} onGradeSelect={handleGradeSelect} />;
  }

  const relevantMix = Object.values(data?.mixes ?? {}).find((mix) =>
    mix.steel_grades.includes(selectedGrade)
  );

  if (!relevantMix) return null;

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Box sx={{ mb: 4 }}>
        <Button
          variant="outlined"
          onClick={() => setSelectedGrade(null)}
          size="large"
          sx={{ mb: 2 }}
        >
          {t("backToGradeSelection")}
        </Button>

        <Typography variant="h3">{selectedGrade}</Typography>

        <Tabs
          value={currentTab}
          onChange={(_, newValue: number) => setCurrentTab(newValue)}
          variant="fullWidth"
          sx={{ mb: 3 }}
        >
          {relevantMix.baskets.map((basket) => (
            <Tab
              key={basket.basket_number}
              label={`Basket ${basket.basket_number}`}
            />
          ))}
        </Tabs>

        {relevantMix.baskets.map((basket, index) => (
          <Box
            key={basket.basket_number}
            role="tabpanel"
            hidden={currentTab !== index}
          >
            {currentTab === index && (
              <BasketLoading
                basketData={basket}
                currentMasses={loadedMasses[basket.basket_number] ?? {}}
                onMassUpdate={(material, mass) =>
                  handleMassUpdate(basket.basket_number, material, mass)
                }
                onSubmit={() => handleBasketSubmit(basket.basket_number)}
                onAddMaterial={(materialName) =>
                  handleAddMaterial(basket.basket_number, materialName)
                }
              />
            )}
          </Box>
        ))}
      </Box>

      <Snackbar
        open={submissionStatus.isOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={submissionStatus.severity}
          sx={{ width: "100%" }}
        >
          {submissionStatus.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};
