import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTenant } from "hooks/settings";
import { Loaded } from "models/loaded";
import { useEffect, useState } from "react";
import {
  OptimisationFailureReason,
  Plan,
  useGetResultQuery,
  useRunSearchMutation,
} from "src/store/api/generatedApi";

type PollingStatus = "ready" | "queueing" | "polling";

export type SyncedResultsState = {
  run: (searchId: number) => void;
  isStale: boolean;
  results: Loaded<{
    plans: Plan[];
    errors: OptimisationFailureReason[];
  } | null>;
};

export const useSyncedResultsState = (
  clientSearchId: number | null,
  revision: number,
  isStale: boolean,
  setIsStale: (isStale: boolean) => void
): SyncedResultsState => {
  const [runSearch] = useRunSearchMutation();
  const tenant = useTenant();

  // Identifier for the search whose result is currently loaded.  Note that
  // this may not match the search whose *parameters* are currently loaded
  const [resultsSearchId, setResultsSearchId] = useState(clientSearchId);
  const [status, setStatus] = useState<PollingStatus>("ready");

  useEffect(() => {
    setResultsSearchId(clientSearchId);
    setStatus("ready");
    setIsStale(false);
  }, [clientSearchId, revision]);

  const { data, isFetching, isError } = useGetResultQuery(
    resultsSearchId == null || status === "queueing"
      ? skipToken
      : { tenantName: tenant, searchId: resultsSearchId },
    status === "polling" ? { pollingInterval: 2000 } : {}
  );

  useEffect(() => {
    if (
      !isFetching &&
      status === "polling" &&
      (isError || (data != null && data.status !== "incomplete"))
    ) {
      setStatus("ready");
    }

    /* Loading up an in-progress optimisation will typically result in an
      invalid initial state; so if the result comes back from the server saying
      that it's still being optimised, trust it, and force the state */
    if (data != null && data.status === "incomplete" && status !== "polling") {
      setStatus("polling");
    }
  }, [data, isFetching, status, isError]);

  const loadedResult =
    data == null || data.search_id !== resultsSearchId ? null : data;

  return {
    run: (runSearchId: number) => {
      setResultsSearchId(runSearchId);
      setIsStale(false);
      setStatus("queueing");

      void runSearch({
        tenantName: tenant,
        searchId: runSearchId,
        body: {},
      })
        .unwrap()
        .then(() =>
          // Don't override other optimisations if the active search has changed
          // in the meantime
          setStatus((current) => (current === "queueing" ? "polling" : current))
        );
    },
    isStale:
      isStale && loadedResult != null && loadedResult.status !== "not_started",
    results: isError
      ? { status: "error" }
      : status === "queueing" || loadedResult == null
      ? { status: "loading" }
      : {
          status: "success",
          data:
            loadedResult.status === "not_started"
              ? null
              : {
                  plans: loadedResult.plans,
                  errors:
                    loadedResult.failure_reason == null
                      ? []
                      : [loadedResult.failure_reason],
                },
          fetching: status === "polling",
        },
  };
};
