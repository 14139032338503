type LimitIndex = 0 | 1 | 2 | 3;

type Nullable<T> = T | null;

export type Limits = [
  Nullable<number>,
  Nullable<number>,
  Nullable<number>,
  Nullable<number>,
];

export const validateAgainstMinimumLimit = (
  minimumLimit: number,
  limit: number | null
): number | null => {
  if (limit === null) {
    return null;
  } else if (minimumLimit > limit) {
    return minimumLimit;
  } else {
    return limit;
  }
};

export const validateAgainstMaximumLimit = (
  maximumLimit: number,
  limit: number | null
): number | null => {
  if (limit === null) {
    return null;
  } else if (maximumLimit < limit) {
    return maximumLimit;
  } else {
    return limit;
  }
};

export const validateAgainstSoftMinimumLimit = (
  index: Exclude<LimitIndex, 1>,
  newLimit: number,
  limit: number | null
): number | null => {
  if (limit === null) {
    return null;
  } else if (index > 1 && newLimit > limit) {
    return newLimit;
  } else if (index === 0 && newLimit < limit) {
    return newLimit;
  } else {
    return limit;
  }
};

export const validateAgainstSoftMaximumLimit = (
  index: Exclude<LimitIndex, 2>,
  newLimit: number,
  limit: number | null
): number | null => {
  if (limit === null) {
    return null;
  } else if (index < 2 && newLimit < limit) {
    return newLimit;
  } else if (index === 3 && newLimit > limit) {
    return newLimit;
  } else {
    return limit;
  }
};

const addNewLimit =
  (limitIndex: LimitIndex) =>
  (limits: Limits, newLimit: number | null): Limits => {
    switch (limitIndex) {
      case 0: {
        if (newLimit === null) {
          return [null, limits[1], limits[2], limits[3]];
        } else {
          return [
            newLimit,
            validateAgainstMinimumLimit(newLimit, limits[1]),
            validateAgainstMinimumLimit(newLimit, limits[2]),
            validateAgainstMinimumLimit(newLimit, limits[3]),
          ];
        }
      }
      case 1: {
        if (newLimit === null) {
          return [limits[0], null, limits[2], limits[3]];
        } else {
          return [
            validateAgainstSoftMinimumLimit(0, newLimit, limits[0]),
            newLimit,
            validateAgainstSoftMinimumLimit(2, newLimit, limits[2]),
            validateAgainstSoftMinimumLimit(3, newLimit, limits[3]),
          ];
        }
      }
      case 2: {
        if (newLimit === null) {
          return [limits[0], limits[1], null, limits[3]];
        } else {
          return [
            validateAgainstSoftMaximumLimit(0, newLimit, limits[0]),
            validateAgainstSoftMaximumLimit(1, newLimit, limits[1]),
            newLimit,
            validateAgainstSoftMaximumLimit(3, newLimit, limits[3]),
          ];
        }
      }
      case 3: {
        if (newLimit === null) {
          return [limits[0], limits[1], limits[2], null];
        } else {
          return [
            validateAgainstMaximumLimit(newLimit, limits[0]),
            validateAgainstMaximumLimit(newLimit, limits[1]),
            validateAgainstMaximumLimit(newLimit, limits[2]),
            newLimit,
          ];
        }
      }
    }
  };

export const addMinimumLimit = addNewLimit(0);
export const addSoftMinimumLimit = addNewLimit(1);
export const addSoftMaximumLimit = addNewLimit(2);
export const addMaximumLimit = addNewLimit(3);
