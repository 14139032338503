import { SummaryTagEnum } from "src/store/api/generatedApi";

type TranslationKey =
  | "notEnoughAvailableScrapDescription"
  | "challengingForScrapyardDescription";

export const getSummaryTagEnumDescriptionTranslationKey = (
  summaryTag: Exclude<SummaryTagEnum, "uses_non_default_baskets">
): TranslationKey => {
  switch (summaryTag) {
    case "challenging_for_scrapyard":
      return "challengingForScrapyardDescription";
    case "not_enough_available_scrap":
      return "notEnoughAvailableScrapDescription";
  }
};
