import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Snackbar, Alert, Typography } from "@mui/material";

import { useTenantTranslation } from "hooks/formatters";
import { useHaveMaterialLimitsUpdated } from "src/store/hooks/materialLimits";
import { useUpdateMaterialLimits } from "hooks/useUpdateMaterialLimits";
import { useSearch } from "contexts/search/provider";
import { useNavigateToSearch } from "hooks/navigation";

export const MaterialLimitsUpdatedSnackbar = () => {
  const { t } = useTenantTranslation();
  const open = useHaveMaterialLimitsUpdated();
  const { setSearchId } = useSearch();

  const navigateToSearch = useNavigateToSearch();

  const [doUpdateMaterialLimits, areUpdateMaterialLimitsLoading] =
    useUpdateMaterialLimits();

  const handleOnClick = async () => {
    const searchId = await doUpdateMaterialLimits();
    setSearchId(searchId);
    navigateToSearch(searchId);
  };

  return (
    <Snackbar open={open}>
      <Alert
        sx={{
          alignItems: "center",
          ".MuiAlert-action": {
            pt: "0px !important",
          },
        }}
        icon={<WarningAmber />}
        severity="error"
        action={
          <LoadingButton
            loading={areUpdateMaterialLimitsLoading}
            onClick={handleOnClick}
            color="secondary"
          >
            {t("updateMaterialLimits")}
          </LoadingButton>
        }
      >
        <Typography variant="body1">
          {t("materialLimitsHaveChanged")}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
