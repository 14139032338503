import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useRunSearch, useSearch } from "contexts/search/provider";
import { useTenantTranslation } from "hooks/formatters";
import { useNavigateToSearch } from "hooks/navigation";
import { useTenant } from "hooks/settings";
import { useUpdateMaterialLimits } from "hooks/useUpdateMaterialLimits";
import React from "react";
import { useRunSearchMutation } from "src/store/api/generatedApi";

type Props = {
  doClose: () => void;
  isOpen: boolean;
};

export const MaterialLimitUpdatesNotSubmitted = ({
  isOpen,
  doClose,
}: Props) => {
  const tenantName = useTenant();

  const runSearchHook = useRunSearch();

  const [runSearchMutation] = useRunSearchMutation();
  const [doUpdateMaterialLimits, areUpdateMaterialLimitsLoading] =
    useUpdateMaterialLimits();
  const navigateToSearch = useNavigateToSearch();
  const { setSearchId } = useSearch();

  const handleRun = React.useCallback(() => {
    runSearchHook();
    doClose();
  }, [runSearchHook, doClose]);

  const handleSubmitAndRun = React.useCallback(async () => {
    const searchId = await doUpdateMaterialLimits();
    void runSearchMutation({ tenantName, searchId, body: {} });
    setSearchId(searchId);
    navigateToSearch(searchId);
    doClose();
  }, [doUpdateMaterialLimits, runSearchMutation, setSearchId, doClose]);

  const { t } = useTenantTranslation();
  return (
    <Dialog open={isOpen} sx={{ zIndex: 2 }}>
      <DialogTitle>{t("materialLimitsUpdatesNotSubmitted")}</DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "space-around" }}>
        <WarningAmber color="error" />
        <Typography>
          {t("materialLimitsHaveBeenUpdatedPleaseSubmitToUse")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={doClose} color="secondary">
          {t("cancel")}
        </Button>
        <Button onClick={handleRun} color="warning" variant="contained">
          {t("searchWithoutUpdates")}
        </Button>
        <LoadingButton
          loading={areUpdateMaterialLimitsLoading}
          onClick={handleSubmitAndRun}
          variant="contained"
        >
          {t("submitUpdatesAndSearch")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
