import { useSearchId } from "hooks/search";
import { useState } from "react";
import { SyncedParametersState, useSyncedParametersState } from "./parameters";
import { SyncedContextState, useSyncedContextState } from "./context";
import { mapLoadedUnpack } from "models/loaded";
import { SyncedResultsState, useSyncedResultsState } from "./results";

export type SyncedSearchState = {
  parameters: SyncedParametersState;
  context: SyncedContextState;
  results: SyncedResultsState;

  clientSearchId: number | null;

  setSearchId: (id: number | null) => void;
};

export const useSyncedSearchState = (): SyncedSearchState => {
  /* This ID is the "root" ID of the search that was originally loaded.  As the
    user makes changes to the search parameters, the *server* ID (defined
    further down) will be updated to reflect the new search that's been created.
    
    As such, this ID is the underlying source of truth, but may be obfuscated
    temporarily by local (client-only) state for some purposes.  When the user
    restores an older version of the search, this identifier is updated, and all
    client-side state is discarded and refreshed from the server.
    
    A "revision" is included with the ID.  This increments whenever either it
    changes, and allows the effects - which trigger new searches to be loaded -
    to detect when the ID *Has* been updated, but to the same value it had
    previously.  Otherwise, you may begin a search, make some changes (which
    *doesn't* change the root ID), and then fail to navigate to the search on
    which you originally started because the destination and root IDs aren't
    technically different. */

  const [clientSearchId, setClientSearchId] = useState(useSearchId());
  const [revision, setRevision] = useState(0);

  const [isStale, setIsStale] = useState(false);

  const setSearchId = (id: number | null) => {
    setClientSearchId(id);
    setRevision((revision) => revision + 1);
  };

  const syncedParameters = useSyncedParametersState(
    clientSearchId,
    revision,
    () => setIsStale(true)
  );
  const loadedContextId = mapLoadedUnpack(
    syncedParameters.client,
    ({ context_id }) => context_id
  );

  const syncedContext = useSyncedContextState(loadedContextId);
  const syncedResults = useSyncedResultsState(
    clientSearchId,
    revision,
    isStale,
    setIsStale
  );

  return {
    parameters: syncedParameters,
    context: syncedContext,
    results: syncedResults,

    clientSearchId,
    setSearchId,
  };
};
