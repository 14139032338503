import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { useTenantTranslation } from "src/hooks/formatters";
import { AccountPageLayout } from "components/common/AccountPageLayout";
import { useSessions } from "../search/SearchSelector/hooks";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import {
  useNavigateToNewBlankSearch,
  useNavigateToNewAutoSearch,
} from "hooks/navigation";
import { SearchesTable } from "./SearchesTable";
import { LoadingButton } from "@mui/lab";
import { SearchType } from "src/store/api/generatedApi";
import { useState } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useTenantData } from "contexts/tenant";

type NewSearchOption = {
  name: string;
  handleClick: () => void;
  icon: OverridableComponent<SvgIconTypeMap>;
};

const getNewAutoSearchOption = (
  autoSearchType: SearchType,
  navigateToNewAutoSearch: (
    searchType: SearchType,
    viewFromTimestamp?: string
  ) => Promise<void>
): NewSearchOption => {
  switch (autoSearchType) {
    case "charging":
      return {
        name: "chargingAutoSearch",
        handleClick: () => navigateToNewAutoSearch("charging"),
        icon: TodayRoundedIcon,
      };
    case "medium_term_charging":
      return {
        name: "mediumTermChargingAutoSearch",
        handleClick: () => navigateToNewAutoSearch("medium_term_charging"),
        icon: DateRangeRoundedIcon,
      };
    case "purchasing":
      return {
        name: "purchasingAutoSearch",
        handleClick: () => navigateToNewAutoSearch("purchasing"),
        icon: CalendarMonthRoundedIcon,
      };
    case "simulator":
      return {
        name: "simulatorAutoSearch",
        handleClick: () => navigateToNewAutoSearch("simulator"),
        icon: TodayRoundedIcon,
      };
  }
};

type SearchOptionsMenuProps = { autoSearchTypes: SearchType[] };

const SearchOptionsMenu = ({ autoSearchTypes }: SearchOptionsMenuProps) => {
  const { t } = useTenantTranslation();
  const [navigateToNewBlankSearch, { isLoading: isBlankSearchLoading }] =
    useNavigateToNewBlankSearch();
  const [navigateToNewAutoSearch, { isLoading: isAutoSearchLoading }] =
    useNavigateToNewAutoSearch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const options = [
    {
      name: "blankSearch",
      handleClick: navigateToNewBlankSearch,
      icon: CalendarTodayRoundedIcon,
    },
    ...autoSearchTypes.map((searchType) =>
      getNewAutoSearchOption(searchType, navigateToNewAutoSearch)
    ),
  ];

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <LoadingButton
        onClick={handleOpen}
        variant="contained"
        size="large"
        loading={isBlankSearchLoading || isAutoSearchLoading}
      >
        {t("newSearch")}
      </LoadingButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 0.5 }}
        MenuListProps={{ disablePadding: true }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => {
              option.handleClick();
              handleClose();
            }}
            sx={{ px: 2, py: 1 }}
          >
            <ListItemIcon>
              <option.icon fontSize="small" color="secondary" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="button">{t(option.name)}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const SearchesPage = () => {
  const { t } = useTenantTranslation();
  const tenantData = useTenantData();

  return (
    <AccountPageLayout
      title={t("searches")}
      actionButton={
        <SearchOptionsMenu autoSearchTypes={tenantData.auto_search_types} />
      }
    >
      <Typography color="text.secondary" sx={{ mb: 5 }}>
        {t("searchesDescription")}
      </Typography>
      <LoadedContent data={useSessions()} disableFetching>
        {(sessions) => <SearchesTable sessions={sessions} />}
      </LoadedContent>
    </AccountPageLayout>
  );
};
