import React, { useContext } from "react";
import { useTenantTranslation } from "hooks/formatters";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { usePlan } from "src/contexts/plan";
import { CopyPlanButton } from "./copyButton";
import { PlanGroup } from "../inventory/inventoryContent";
import { MultiSelect } from "src/components/common/inputs/multiSelect";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  FormLabel,
  Popover,
  useTheme,
  Switch,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import {
  SearchChemistryGroups,
  SearchProductGroups,
} from "contexts/search/context";
import { PlanTableDependencies } from "../dependencies";
import {
  PlanMixes,
  useProductionGroups,
  useProductionMixes,
} from "./productionContent";
import { useProductionContextTransposeHotkey } from "hooks/hotkeys";
import { Period } from "hooks/periodIndex";
import { PeriodMaterialSummary } from "src/store/api/generatedApi";
import { useTenantData } from "contexts/tenant";

export enum Measure {
  Percentage = "%",
  Mass = "t",
}

export enum Group {
  Product = "product",
  Chef = "chef",
}

enum ContextEmpty {
  True,
}

type Context = {
  group: Group;
  measure: Measure;
  period: Period;
  steelGrades: Set<string>;
  periodMaterialSummaries: PeriodMaterialSummary[];
  groups: PlanGroup[];
  mixes: PlanMixes;
  grouping: SearchProductGroups | SearchChemistryGroups;
  isTransposed: boolean;
};

const OptionContext = React.createContext<ContextEmpty | Context>(
  ContextEmpty.True
);

const useOptions = () => {
  const context = useContext(OptionContext);
  if (context === ContextEmpty.True) {
    throw new Error(`Option context not set`);
  }
  return context;
};

export const useGroupOption = () => {
  const { group } = useOptions();
  return group;
};

export const useMeasureOption = () => {
  const { measure } = useOptions();
  return measure;
};

export const usePeriodOption = () => {
  const { period } = useOptions();
  return period;
};

export const useSteelGradesOption = () => {
  const { steelGrades } = useOptions();
  return steelGrades;
};

export const useGroups = () => {
  const { groups } = useOptions();
  return groups;
};

export const useMixes = () => {
  const { mixes } = useOptions();
  return mixes;
};
export const usePeriodMaterialSummaries = () => {
  const { periodMaterialSummaries } = useOptions();
  return periodMaterialSummaries;
};

export const useGrouping = () => {
  const { grouping } = useOptions();
  return grouping;
};

export const useIsTransposed = () => {
  const { isTransposed } = useOptions();
  return isTransposed;
};

type Props = {
  planId: number;
  productGroups: SearchProductGroups | null;
  chemistryGroups: SearchChemistryGroups;
  hasProductGroups: boolean;
  dependencies: PlanTableDependencies;
  period: Period;
};

export const ProductionContext = ({
  planId,
  productGroups,
  chemistryGroups,
  dependencies,
  children,
  hasProductGroups,
  period,
}: React.PropsWithChildren<Props>) => {
  const { default_mix_breakdown: defaultMixBreakdown } = useTenantData();
  const theme = useTheme();

  const { t } = useTenantTranslation();

  const buttonRef = React.useRef(null);

  const [isTransposed, setIsTransposed] = useProductionContextTransposeHotkey();
  const [group, setGroup] = React.useState<Group>(Group.Chef);
  const [measure, setMeasure] = React.useState<Measure>(() => {
    switch (defaultMixBreakdown) {
      case "percentage":
        return Measure.Percentage;
      case "mass":
        return Measure.Mass;
    }
  });

  const [steelGrades, setSteelGrades] = React.useState<Set<string>>(new Set());
  const [areSettingsOpen, setAreSettingsOpen] = React.useState(false);

  const grouping =
    group === Group.Product && productGroups ? productGroups : chemistryGroups;

  const materialSummary = React.useMemo(() => {
    switch (group) {
      case Group.Product:
        return (
          dependencies.plan.summary.product_group_mix_material_summary ??
          dependencies.plan.summary.mix_material_summary
        );
      case Group.Chef:
        return dependencies.plan.summary.mix_material_summary;
    }
  }, [
    group,
    dependencies.plan.summary.product_group_mix_material_summary,
    dependencies.plan.summary.mix_material_summary,
  ]);

  const mixSummaries = React.useMemo(() => {
    switch (group) {
      case Group.Product:
        return (
          dependencies.plan.summary.product_group_mix_summary ??
          dependencies.plan.summary.mix_summary
        );
      case Group.Chef:
        return dependencies.plan.summary.mix_summary;
    }
  }, [
    group,
    dependencies.plan.summary.product_group_mix_summary,
    dependencies.plan.summary.mix_summary,
  ]);

  const periodMaterialSummaries =
    dependencies.plan.summary.period_material_summary.filter(
      (summary) => summary.period === period
    );

  const mixes = useProductionMixes(
    dependencies.materials,
    grouping,
    materialSummary,
    mixSummaries,
    period
  );

  const groups = useProductionGroups(mixes, mixSummaries, grouping, period);

  const context = React.useMemo(
    () => ({
      group,
      measure,
      period,
      steelGrades,
      periodMaterialSummaries,
      groups,
      mixes,
      grouping,
      isTransposed,
    }),
    [
      group,
      measure,
      period,
      steelGrades,
      periodMaterialSummaries,
      groups,
      mixes,
      grouping,
      isTransposed,
    ]
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          gap={2}
          alignItems="start"
          justifyContent="flex-start"
        >
          <MultiSelect
            sx={{ width: 250 }}
            options={relevantSteelGrades(
              group,
              productGroups,
              chemistryGroups,
              groups
            )}
            values={[...steelGrades]}
            setValues={(steelGradesArray) =>
              setSteelGrades(new Set(steelGradesArray))
            }
            label={t("searchForSteelGrade")}
            small
          />
        </Stack>
        <Stack direction="row" alignItems="start">
          <LoadedContent disableFullWidth data={usePlan(planId).plan}>
            {(plan) => {
              const isPercentageBreakdown = measure === Measure.Percentage;
              const groupType = (() => {
                switch (group) {
                  case Group.Product:
                    return "productGroups";
                  case Group.Chef:
                    return "chemistryGroups";
                }
              })();

              return (
                <CopyPlanButton
                  plan={plan}
                  percentageBreakdown={isPercentageBreakdown}
                  period={period}
                  groupType={groupType}
                />
              );
            }}
          </LoadedContent>

          <Button
            startIcon={<Settings />}
            variant="text"
            onClick={() => setAreSettingsOpen((areOpen) => !areOpen)}
            ref={buttonRef}
          >
            {t("configuration")}
          </Button>
          <Popover
            anchorEl={buttonRef.current}
            open={areSettingsOpen}
            onClose={() => setAreSettingsOpen(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            sx={{
              borderRadius: 1,
            }}
          >
            <Stack sx={{ padding: 2, gap: 2, width: 150 }}>
              <FormControl sx={{ gap: 0.5 }}>
                <FormLabel>{t("units")}</FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  value={measure}
                  exclusive
                  onChange={(_, measure) => {
                    if (measure !== null) {
                      setMeasure(measure as Measure);
                    }
                  }}
                >
                  <ToggleButton
                    sx={{
                      px: 1.5,
                      py: 0.5,
                      borderColor: theme.palette.grey[100],
                    }}
                    value={Measure.Percentage}
                  >
                    %
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      px: 1.5,
                      py: 0.5,
                      borderColor: theme.palette.grey[100],
                    }}
                    value={Measure.Mass}
                  >
                    t
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
              {hasProductGroups ? (
                <FormControl
                  sx={{
                    gap: 0.5,
                    "& .Mui-focused": {
                      color: theme.palette.text.secondary,
                    },
                  }}
                >
                  <FormLabel>{t("Group by")}</FormLabel>
                  <RadioGroup
                    value={group}
                    onChange={(_, group) => setGroup(group as Group)}
                  >
                    <FormControlLabel
                      value={Group.Chef}
                      sx={{ gap: 0.5, marginLeft: 0 }}
                      control={<Radio size="small" sx={{ padding: 0 }} />}
                      label={t("chemistryGroup")}
                    />
                    <FormControlLabel
                      value={Group.Product}
                      sx={{ gap: 0.5, marginLeft: 0 }}
                      control={<Radio size="small" sx={{ padding: 0 }} />}
                      label={t("productGroup")}
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
              <FormLabel
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {t("transposeTable") + " "}
                <Switch
                  size="small"
                  checked={isTransposed}
                  onChange={() => setIsTransposed((transposed) => !transposed)}
                />
              </FormLabel>
            </Stack>
          </Popover>
        </Stack>
      </Stack>
      <OptionContext.Provider value={context}>
        {children}
      </OptionContext.Provider>
    </>
  );
};

const relevantSteelGrades = (
  group: Group,
  productGroups: null | SearchProductGroups,
  chemistryGroups: SearchChemistryGroups,
  groups: PlanGroup[]
) => {
  const groupIds = new Set(groups.map((group) => group.group));
  const grouping = (() => {
    switch (group) {
      case Group.Product: {
        if (productGroups) {
          return productGroups;
        } else {
          return chemistryGroups;
        }
      }
      case Group.Chef: {
        return chemistryGroups;
      }
    }
  })();
  return grouping.byIndex
    .filter(({ id }) => groupIds.has(id))
    .flatMap((group) => group.steel_grades.map(({ name }) => name));
};
